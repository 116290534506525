import { createAction } from 'redux-actions';
import { message } from 'antd';
import * as types from './actionTypes';
import * as api from '../../utils/api';
import * as cookie from '../../utils/cookiesTool';

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});

export function doIsLoggedIn(history) {
  return dispatch => {
    const stormedToken = cookie.getCookie('stormedToken');
    if (stormedToken === '') {
      history.push(`/login`);
    } else {
      api.cCheckToekn().then(result => {
      }).catch((errMsg) => {
        if (errMsg === 'E401') {
          message.info('Invalid Token');
          cookie.setCookie('stormedToken', '');
          history.push('/');
        }
      })
    }
  }
}

export const doChangeStatusFilter = createAction(types.UPDATE_STATUS_FILTER)
export const doChangeSearchText = createAction(types.UPDATE_SEARCH_TEXT)
export const doUpdateCoupons = createAction(types.UPDATE_COUPONS)
export const doUpdateCouponIdList = createAction(types.UPDATE_COUPON_ID_LIST)
export function doLoadCoupons() {  
  return dispatch => {
    api.rCoupons().then(result => { 
      const couponIdList = [];
      const coupons = {};
      result.forEach(coupon => {
        couponIdList.push(`${coupon.couponId}`);
        coupons[coupon.couponId] = coupon;
      })
      dispatch(doUpdateCoupons({ coupons }));      
      dispatch(doUpdateCouponIdList({ couponIdList }));
    }).catch((errMsg) => {
      console.log(errMsg);
    })
  }
}


