import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import * as api from '../../utils/api';


export const doUpdateCategory = createAction(types.UPDATE_CATEGORY)
export function doClickCategory(category) {  
  return dispatch => {
    dispatch(doUpdateCategory({ category }));
    if (category === '媒體報導') {
      dispatch(doLoadNews());
    }
  }
}

export const doUpdateMedias = createAction(types.UPDATE_MEDIAS)
export const doUpdateCurrentMedia = createAction(types.UPDATE_CURRENT_MEDIA)
export const doUpdateNews = createAction(types.UPDATE_NEWS)
export const doUpdateCurrentNews = createAction(types.UPDATE_CURRENT_NEWS)
export function doLoadNews() {  
  return (dispatch, getState) => {
    return api.rNews().then(result => {
      const state = getState();
      dispatch(doUpdateMedias({ medias: result.mediaList }));
      // dispatch(doUpdateCurrentMedia({ currentMedia: Object.keys(result)[0] }));
      dispatch(doUpdateNews({ news: result.news }));
      dispatch(doUpdateCurrentNews({ currentNews: {'title': '', 'image': '/newsData/image/None'} }));
    }).catch((errMsg) => {
      console.log(errMsg);
    })
  }
}


export const doUpdateActivities = createAction(types.UPDATE_ACTIVITIES)
export function doLoadActivities() {  
  return dispatch => {
    return api.rActivities().then(result => {
      dispatch(doUpdateActivities({ activities: result }));
    }).catch((errMsg) => {
      console.log(errMsg);
    })       
  }
}