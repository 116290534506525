import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import LoginFbComponent from './component';


const mapStateToProps = (state) => ({
});

const mapDispatchToProps = dispatch => ({
  onClickLoginFb: (history) => {
    dispatch(actions.doClickLoginFb(history));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginFbComponent);