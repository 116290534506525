import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// images
import PigsFeet from '../../images/PigsFeet.png'
import DecorativeLine from '../../images/DecorativeLine.svg'
import TitleAbout from '../../images/title_about.svg'


class AboutUsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  componentDidMount() {
    this.props.onLoadAboutUs();
  }
  render() {
    const {
      about
    } = this.props;
    return (
      <div>
        <section className="AboutBg">

          <div className="container">

            <div className="row">
              <div className="col"><img className="mainPage_Title rounded mx-auto d-block" src={TitleAbout} alt="" /></div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <img className="aboutImg rounded mx-auto d-block" src={PigsFeet} alt="" />
              </div>
              <div className="col-md-8">
                <p className="aboutText">{about}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-8">
                <Link to={`/story`}>
                  <button className="aboutBtn" type="button">了解更多</button>
                </Link>
              </div>
            </div>

          </div>
        </section>

      </div>
    )
  }
}

export default AboutUsComponent;