import { connect } from 'react-redux';
// import * as actions from './actions';
// import * as selectors from './selectors';
import StoreComponent from './component';


const mapStateToProps = (state) => ({
  // storeIdList: selectors.getStoreIdList(state),
  // stores: selectors.getStores(state),
  // selectedStore: selectors.getSelectedStore(state)
});

const mapDispatchToProps = dispatch => ({
  // onClickStore: (storeId) => {
  //   dispatch(actions.doClickStore({ storeId }));
  // },
  // onLoadStores: () => {
  // 	console.log("ccc")
  // 	dispatch(actions.doFetchStores());
  // }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreComponent);