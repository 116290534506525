import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
import coupons from '../modules/coupons';
// images
import myAccountBanner from '../images/myAccountBanner.png'


class CouponsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
  	return (
	    <div id="page-coupon">
        <Helmet>
          <title>鄧師傅功夫菜</title>
          <meta name="description" content="飄香高雄35載，滷豬腳年銷售超越50座臺北101大樓高度! 馳名海內外，獲得媒體一致推薦。Chef Teng founded in 1984, Taiwan, is famous for signature stewed pork knuckles and has won awards and recognition from news media around the world." />
        </Helmet>
        <headerBar.container/>

        {/* <div className="MyAccountBannerTitle">
          <p>我的帳戶</p>
        </div> */}

        <div className="row TitleBox">
          <p className="col-auto mr-auto Title">優惠券明細</p>
          <div className="col-auto DirectionTitle">首頁 / 我的帳戶 / 優惠券明細</div>
        </div>

        <coupons.container/>

        <footer.container/>
	    </div>
	  )
  }
}

export default CouponsPageComponent;
