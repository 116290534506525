import { createAction } from 'redux-actions';
import { fromJS } from 'immutable';
import Modal from 'antd/lib/modal';
import * as types from './actionTypes';
import * as api from '../../utils/api';
import * as cookie from '../../utils/cookiesTool';


export const postRequest = createAction(types.POST_REQUEST)
export const postSuccess = createAction(types.POST_SUCCESS)
export const postFailure = createAction(types.POST_FAILURE)

export function doLoadPurchaserEmail() {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(doEditRegistration({ value: state.getIn(['shoppingProgress', 'purchaserName']), type: 'name' }));
    dispatch(doEditRegistration({ value: state.getIn(['shoppingProgress', 'purchaserGender']), type: 'gender' }));
    dispatch(doEditRegistration({ value: state.getIn(['shoppingProgress', 'purchaserEmail']), type: 'email' }));
    dispatch(doEditRegistration({ value: state.getIn(['shoppingProgress', 'purchaserPhone']), type: 'phone' }));
    dispatch(doEditRegistration({ value: state.getIn(['shoppingProgress', 'purchaserCity']), type: 'city' }));
    dispatch(doEditRegistration({ value: state.getIn(['shoppingProgress', 'purchaserAddress']), type: 'address' }));    
    api.rCities().then(result => {
      dispatch(doEditRegistration({ value: fromJS(result), type: 'TaiwanCity' }));
    }).catch((errMsg) => {
      console.log(errMsg)
    })
  }
}

export const doEditRegistration = createAction(types.EDIT_REGISTRATION)
export function doSubmitRegistration(history) {
  return (dispatch, getState) => {
  	const state = getState();
  	const name = state.getIn(['registration', 'name']);
    const gender = state.getIn(['registration', 'gender']);
    const email = state.getIn(['registration', 'email']);
    const phone = state.getIn(['registration', 'phone']);
    const city = state.getIn(['registration', 'city']);
    const address = state.getIn(['registration', 'address']);
    const password = state.getIn(['registration', 'password']);
    const confirmPassword = state.getIn(['registration', 'confirmPassword']);

    if (password !== confirmPassword) {
      dispatch(doEditRegistration({ type: 'password', value: '' }));
      dispatch(doEditRegistration({ type: 'confirmPassword', value: '' }));
      return dispatch(postFailure({ errMsg: '密碼不一致，請重新輸入'}));
    }
    dispatch(postRequest());
    api.cRegistration(name, gender, email, phone, city, address, password).then(result => {
      cookie.setCookie('stormedToken', result.stormedToken);
      Modal.success({
        title: `註冊成功`,
        okText: `確定`,
        onOk: () => {
          dispatch(postSuccess());
          history.push(`/orders`)
        },
      });
    }).catch((errMsg) => {
      if  (errMsg === 'E002') {
        // dispatch(postFailure({ errMsg: '密碼需8位數以上且英數字大小寫混和'}));
        dispatch(postFailure({ errMsg: '密碼需4位數以上' }));
      } else if (errMsg === 'E004') {
        dispatch(postFailure({ errMsg: '此手機號碼 / Email 已註冊，請聯絡鄧師傅客服人員。'}));
      }
    })    
  }
}
