import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import SpecialsComponent from './component';


const mapStateToProps = (state) => ({
  specialsList: selectors.getSpecialsList(state),
});

const mapDispatchToProps = dispatch => ({
  onLoadSpecials: () => {
    dispatch(actions.doLoadSpecials());
  },
  onAddToShoppingCart: (specials, buyProducts, buyProductsQty) => {
    dispatch(actions.doAddToShoppingCart(specials, buyProducts, buyProductsQty));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpecialsComponent);