export function getExistShipping(products, buyProducts, buyRedeems, buySpecials) {
    let canShippingSelf = true;
    let canShippingDelivery = true;
    let canShippingTC = true;
    let shippingSelfDateList = [];
    let shippingDeliveryDateList = [];
    let shippingTCDateList = [];
    
    buyProducts.keySeq().forEach(pId => {
      if (products.getIn([pId, 'shippingSelf']) === false) {
        canShippingSelf = false;
      }
      if (products.getIn([pId, 'shippingDelivery']) === false) {
        canShippingDelivery = false;
      }
      if (products.getIn([pId, 'shippingTC']) === false) {
        canShippingTC = false;
      }
      if (products.getIn([pId, 'shippingSelfDateList']).size > 0) {
        if (shippingSelfDateList.length === 0) {
          shippingSelfDateList = products.getIn([pId, 'shippingSelfDateList']).toJS();
        } else {
          shippingSelfDateList = shippingSelfDateList.filter(shippingDate => products.getIn([pId, 'shippingSelfDateList']).indexOf(shippingDate) !== -1);
        }
      }
      if (products.getIn([pId, 'shippingDeliveryDateList']).size > 0) {
        if (shippingDeliveryDateList.length === 0) {
          shippingDeliveryDateList = products.getIn([pId, 'shippingDeliveryDateList']).toJS();
        } else {
          shippingDeliveryDateList = shippingDeliveryDateList.filter(shippingDate => products.getIn([pId, 'shippingDeliveryDateList']).indexOf(shippingDate) !== -1);
        }
      }
      if (products.getIn([pId, 'shippingTCDateList']).size > 0) {
        if (shippingTCDateList.length === 0) {
          shippingTCDateList = products.getIn([pId, 'shippingTCDateList']).toJS();
        } else {
          shippingTCDateList = shippingTCDateList.filter(shippingDate => products.getIn([pId, 'shippingTCDateList']).indexOf(shippingDate) !== -1);
        }
      }
    });

    buyRedeems.keySeq().forEach(packageId => {
      if (products.getIn([packageId, 'shippingSelf']) === false) {
        canShippingSelf = false;
      }
      if (products.getIn([packageId, 'shippingDelivery']) === false) {
        canShippingDelivery = false;
      }
      if (products.getIn([packageId, 'shippingTC']) === false) {
        canShippingTC = false;
      }
      if (products.getIn([packageId, 'shippingSelfDateList']).size > 0) {
        if (shippingSelfDateList.length === 0) {
          shippingSelfDateList = products.getIn([packageId, 'shippingSelfDateList']).toJS();
        } else {
          shippingSelfDateList = shippingSelfDateList.filter(shippingDate => products.getIn([packageId, 'shippingSelfDateList']).indexOf(shippingDate) !== -1);
        }
      }
      if (products.getIn([packageId, 'shippingDeliveryDateList']).size > 0) {
        if (shippingDeliveryDateList.length === 0) {
          shippingDeliveryDateList = products.getIn([packageId, 'shippingDeliveryDateList']).toJS();
        } else {
          shippingDeliveryDateList = shippingDeliveryDateList.filter(shippingDate => products.getIn([packageId, 'shippingDeliveryDateList']).indexOf(shippingDate) !== -1);
        }
      }
      if (products.getIn([packageId, 'shippingTCDateList']).size > 0) {
        if (shippingTCDateList.length === 0) {
          shippingTCDateList = products.getIn([packageId, 'shippingTCDateList']).toJS();
        } else {
          shippingTCDateList = shippingTCDateList.filter(shippingDate => products.getIn([packageId, 'shippingTCDateList']).indexOf(shippingDate) !== -1);
        }
      }
    });

    buySpecials.valueSeq().forEach(specials => {
      if (specials.getIn(['shippingSelf']) === false) {
        canShippingSelf = false;
      }
      if (specials.getIn(['shippingDelivery']) === false) {
        canShippingDelivery = false;
      }
      if (products.getIn(['shippingTC']) === false) {
        canShippingTC = false;
      }
      if (specials.getIn(['shippingSelfDateList']).size > 0) {
        if (shippingSelfDateList.length === 0) {
          shippingSelfDateList = specials.getIn(['shippingSelfDateList']).toJS();
        } else {
          shippingSelfDateList = shippingSelfDateList.filter(shippingDate => specials.getIn(['shippingSelfDateList']).indexOf(shippingDate) !== -1);
        }
      }
      if (specials.getIn(['shippingDeliveryDateList']).size > 0) {
        if (shippingDeliveryDateList.length === 0) {
          shippingDeliveryDateList = specials.getIn(['shippingDeliveryDateList']).toJS();
        } else {
          shippingDeliveryDateList = shippingDeliveryDateList.filter(shippingDate => specials.getIn(['shippingDeliveryDateList']).indexOf(shippingDate) !== -1);
        }
      }
      if (products.getIn(['shippingTCDateList']).size > 0) {
        if (shippingTCDateList.length === 0) {
          shippingTCDateList = products.getIn(['shippingTCDateList']).toJS();
        } else {
          shippingTCDateList = shippingTCDateList.filter(shippingDate => products.getIn(['shippingTCDateList']).indexOf(shippingDate) !== -1);
        }
      }
    });

    return {canShippingSelf, canShippingDelivery, canShippingTC, shippingSelfDateList, shippingDeliveryDateList, shippingTCDateList}
}