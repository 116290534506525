import React, { Component } from 'react';
import { fromJS } from 'immutable';
import { Helmet } from 'react-helmet';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
import featured from '../modules/featured';
import googleMap from '../modules/googleMap';
import store from '../modules/store';
// images
import kungfuNoodleBanner from '../images/kungfuNoodleBanner.png'
import knImg from '../images/knImg.png'
import knImgLogo from '../images/knImgLogo.png'
import btn_close from '../images/slider-close-icon.svg'
// import locationIcon from '../images/locationIcon.png';


class KungfuNoodlePageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storeData: fromJS({
        storeName: '夢時代概念店',
        storeHour: '11:00 ~ 21:00',
        storePhone: '078227822',
        storeAddress: '高雄夢時代購物廣埸 B1F 美食街',
        description: '',
        longitude: 120.305896,
        latitude: 22.594644
      })
    };
  }
  render() {
  	return (
	    <div id="page-kungfuNoodle">
      <Helmet>
        <title>鄧師傅功夫菜-煨功夫麵</title>
        <meta name="description" content="飄香高雄35載，滷豬腳年銷售超越50座臺北101大樓高度! 馳名海內外，獲得媒體一致推薦。Chef Teng founded in 1984, Taiwan, is famous for signature stewed pork knuckles and has won awards and recognition from news media around the world." />
      </Helmet>
        <headerBar.container/>

        {/* <div className="KunfuBannerTitle">
          <p className="center">煨功夫麵</p>
        </div>  */}
        <div className="row TitleBox">
          <p className="col-auto mr-auto Title">煨功夫麵</p>
          <p className="col-auto DirectionTitle">首頁 / 煨功夫麵</p>
        </div>

        <div className="KunfuContent">
          <div className="row">
            <div className="col-lg-7 order-xl-2 order-lg-2">
              <div className="row align-items-center">
                <div className="col-md-9">
                  <h1>“煨 功夫麵”，是一碗與眾不同的麵。</h1>
                </div>
                <div className="col-md-3 ml-auto text-right">
                  <img src={knImgLogo} className="img-fluid" alt=""/>
                </div>
              </div>
              <div className="video-container d-lg-none d-xl-none">
                <iframe width="640" height="360" src="https://www.youtube.com/embed/SLOnAsyd-Zw" frameBorder="0" gesture="media" allow="encrypted-media" allowFullScreen></iframe>
              </div>
              <p>
                “煨”是指將食物放在陶罐或鍋裡，用炭火慢慢燒煮，萃取食材的精華於湯中。
                鄧師傅選擇用“煨”，將軟Q麵條、香濃奶湯、特色食材同時煨煮，讓食材與湯頭的鮮美和麵條緊密融合在一起，層次豐富的味道，將挑動您的味蕾，帶來全新的飲食體驗。
                <br/>
                <br/>
                我們的湯頭選用上等豬肉與老母雞，時經一天一夜，熬出雪白鮮香的濃郁奶湯不但膠質豐富，口感鮮甜且齒頰留香，搭配特選麵條，不但能吸附湯汁精華，口感更是彈牙順口。
                再加上作法費工費時的宴席主菜，不論是上海菜中的雪菜黃魚、東坡子排、紅燒獅子頭，四川菜中的川味牛三寶、臺菜中的土豆豬腳，還是客家菜中的酸菜滑魚片，可都是大飯店才吃得到的美味佳餚。
                在這湯頭、麵條、主菜三合一的奏鳴曲下，譜出一道道口感豐富、味道多層次的美味饗宴。
                這就是鄧師傅餐飲集團用心為您烹調的“煨 功夫麵”。
              </p>
              <div className="row d-none d-lg-block">
                <div className="col-auto mr-auto"></div>
                <button className="col-md-4 col-auto GBtn" type="button" data-toggle="modal" data-target="#VideoModal">影片介紹</button>                
              </div>
            </div>
            <div className="col-lg-5 order-xl-1 order-lg-1 text-center">
              <img src={knImg} className="img-fluid" alt=""/>
            </div> 
          </div>
        </div>

        <featured.container
          title={"功夫麵料理"}
          type={"kungfuNoodle"}
        />

        <div className="row TitleBox">
          <p className="col-auto mr-auto Title">門市資訊</p>
        </div>
      
        <div className="container align-items-center StoresMap">
          <div div className="row justify-content-center align-items-center">
            <div className="col-lg-7 MapBox">
              {
                this.state.storeData
                &&
                <googleMap.component
                  longitude={this.state.storeData.getIn(['longitude'])}
                  latitude={this.state.storeData.getIn(['latitude'])}
                />          
              } 
            </div>
            <div className="col-lg-5">
              <store.container
                storeData={this.state.storeData}
              />
            </div>
          </div>
        </div>

        <footer.container/>
        <div className="modal fade bd-example-modal-lg" id="VideoModal" tabIndex="-1" role="dialog" aria-labelledby="VideoModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="VideoModalLabel">煨功夫麵2015</h5>
                <img className="close SliderBoxCloseBtn" src={btn_close} data-dismiss="modal" aria-label="Close"/>
              </div>
              <div className="modal-body">
                <div className="video-container">
                  <iframe width="640" height="360" src="https://www.youtube.com/embed/SLOnAsyd-Zw" frameBorder="0" gesture="media" allow="encrypted-media" allowFullScreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
	    </div>
	  )
  }
}

export default KungfuNoodlePageComponent;
