import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import configureStore from './store/configureStore.js';
import LoginPageComponent from './pages/loginPage';
import ForgotPasswordPageComponent from './pages/forgotPasswordPage';
import ResetPasswordWithTokenPageComponent from './pages/resetPasswordWithTokenPage';
import RegistrationPageComponent from './pages/registrationPage';
import StoresPageComponent from './pages/storesPage';
import MainPageComponent from './pages/mainPage';
import ShoppingNotesPageComponent from './pages/shoppingNotesPage';
import MediaPageComponent from './pages/mediaPage';
import NewsPageComponent from './pages/newsPage';
import DeliveryPageComponent from './pages/deliveryPage';
import StoryPageComponent from './pages/storyPage';
import KungfuNoodlePageComponent from './pages/kungfuNoodlePage';
import HeatingInstructionsPageComponent from './pages/heatingInstructionsPage';
import ProductHistoryPageComponent from './pages/productHistoryPage';
import ProductsPageComponent from './pages/productsPage';
import ProductPageComponent from './pages/productPage';
// import SpecialsPageComponent from './pages/specialsPage';
import RedeemsPageComponent from './pages/redeemsPage';
import RedeemPageComponent from './pages/redeemPage';
import OrdersPageComponent from './pages/ordersPage';
import ProfilePageComponent from './pages/profilePage';
import ResetPasswordWithOldPasswordPageComponent from './pages/resetPasswordWithOldPasswordPage';
import CouponsPageComponent from './pages/couponsPage';
import OrderPageComponent from './pages/orderPage';
import ShoppingProgressPageComponent from './pages/shoppingProgressPage';
import ShoppingCompletedPageComponent from './pages/shoppingCompletedPage';
import ShoppingEcpayPageComponent from './pages/shoppingEcpayPage';
import ShoppingEcpay2PageComponent from './pages/shoppingEcpay2Page';
import OrdersSearchPageComponent from './pages/ordersSearchPage';
import RedeemInfoPageComponent from './pages/redeemInfoPage';
import MemberNoticePageComponent from './pages/memberNoticePage';
import MemberQAPageComponent from './pages/memberQAPage';
import MemberInfoPageComponent from './pages/memberInfoPage';
import AboutUsPageComponent from './pages/aboutUsPage';
import TestPageComponent from './pages/testPage';
import ScrollToTop from './utils/ScrollToTop';
import EnglishPageComponent from './pages/englishPage.js';
import LoveLightingPageComponent from './pages/loveLightingPage.js';

//import './css/bootstrap.min.js';
import '../node_modules/antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.css';
import './css/main.css';


ReactDOM.render(
  <Provider store={configureStore}>
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <Route path="/" exact component={MainPageComponent} />
          <Route path="/login" component={LoginPageComponent} />
          <Route path="/password/forgot" component={ForgotPasswordPageComponent} />
          <Route path="/registration" component={RegistrationPageComponent} />
          <Route path="/password/reset/:token" component={ResetPasswordWithTokenPageComponent} />
          <Route path="/stores" component={StoresPageComponent} />
          <Route path="/shoppingNotes" component={ShoppingNotesPageComponent} />
          <Route path="/aboutUs" component={AboutUsPageComponent} />
          <Route path="/media" component={MediaPageComponent} />
          <Route path="/news" component={NewsPageComponent} />
          <Route path="/delivery" component={DeliveryPageComponent} />
          <Route path="/story" component={StoryPageComponent} />
          <Route path="/kungfu-noodle" component={KungfuNoodlePageComponent} />
          <Route path="/heating-instructions" component={HeatingInstructionsPageComponent} />
          <Route path="/product-history" component={ProductHistoryPageComponent} />
          <Route path="/products/:categoryId" component={ProductsPageComponent} />
          <Route path="/products" component={ProductsPageComponent} />
          {/* <Route path="/specials" component={SpecialsPageComponent} /> */}
          <Route path="/redeems/:categoryId" component={RedeemsPageComponent} />
          <Route path="/redeems" component={RedeemsPageComponent} />
          <Route path="/redeem/:productId" component={RedeemPageComponent} />
          <Route path="/線上購物/:categoryId" component={ProductsPageComponent} />
          <Route path="/線上購物" component={ProductsPageComponent} />
          <Route path="/product/:productId" component={ProductPageComponent} />
          <Route path="/orders/search" component={OrdersSearchPageComponent} />
          <Route path="/orders" component={OrdersPageComponent} />
          <Route path="/profile" component={ProfilePageComponent} />
          <Route path="/password/reset" component={ResetPasswordWithOldPasswordPageComponent} />
          <Route path="/coupons" component={CouponsPageComponent} />
          <Route path="/order/:orderId" component={OrderPageComponent} />
          <Route path="/shopping/progress" component={ShoppingProgressPageComponent} />
          <Route path="/shopping/completed" component={ShoppingCompletedPageComponent} />
          <Route path="/shopping/ecpay" component={ShoppingEcpayPageComponent} />
          <Route path="/shopping/ecpay2" component={ShoppingEcpay2PageComponent} />
          <Route path="/redeemInfo" component={RedeemInfoPageComponent} />
          <Route path="/memberNotice" component={MemberNoticePageComponent} />
          <Route path="/memberQA" component={MemberQAPageComponent} />
          <Route path="/memberInfo" component={MemberInfoPageComponent} />
          <Route path="/en" component={EnglishPageComponent} />
          <Route path="/loveLighting" component={LoveLightingPageComponent} />

          <Route path="/test" component={TestPageComponent} />
          <Route path="*" component={MainPageComponent} />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
