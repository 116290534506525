import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Spin } from 'antd';


class LoginComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
    this.props.onIsLoggedIn(this.props.history);
  }  
  render() {
    const { 
      account, password, errMessage, showLoading,
      onEditLogin, onSubmitLogin
    } = this.props;
  	return (
	    <div>
        <p className="LoginTitle">會員登入</p>


        <form onSubmit={(event) => {event.preventDefault();onSubmitLogin(this.props.history)}}>

          <input type="text" placeholder="email / 手機號碼" 
            value={account} 
            onChange={(event) => onEditLogin(event.target.value, "account")}
            required 
          />
          
          <input type="password" placeholder="密碼" 
            value={password}
            onChange={(event) => onEditLogin(event.target.value, "password")}
            required 
          />

          <p>{errMessage}</p>
          
          <div className="LoginTextBox">
            <a className="LoginText" href="/password/forgot"><p>忘記密碼</p></a>
            <p className="LoginText">/</p>
            <a className="LoginText" href="/registration"><p>我要註冊</p></a>
          </div>

          {
            showLoading
            ?
            <button className="LoginBtn"><Spin /></button>
            :
            <button className="LoginBtn" type="submit">登入</button>
          }
        </form>
	    </div>
	  )
  }
}

export default withRouter(LoginComponent);