import { createSelector } from 'reselect';


export const getId = state => state.getIn(['order', 'id']);
export const getPaymentDate = state => state.getIn(['order', 'paymentDate']);
export const getPaymentMethod = state => state.getIn(['order', 'paymentMethod']);
export const getPaymentStatus = state => state.getIn(['order', 'paymentStatus']);
export const getStatus = state => state.getIn(['order', 'status']);
export const getTotalAmount = state => state.getIn(['order', 'totalAmount']);
export const getShippingCost = state => state.getIn(['order', 'shippingCost']);
export const getBalanceDue = state => state.getIn(['order', 'balanceDue']);
export const getPackageList = state => state.getIn(['order', 'packageList']);
export const getRedeemList = state => state.getIn(['order', 'redeemList']);

export const getUseRedeem = createSelector(
  [getRedeemList],
  (redeemList) => {
    let totalRedeem = 0;
    redeemList.forEach(package_ => {totalRedeem += package_.getIn(['totalRedeem'])});
    return totalRedeem;
  }
)
