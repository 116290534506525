export const UPDATE_PRODUCTS = 'products/UPDATE_PRODUCTS'
export const UPDATE_PRODUCT_ID_LIST = 'products/UPDATE_PRODUCT_ID_LIST'
export const UPDATE_PRODUCT_CATEGORIES = 'products/UPDATE_PRODUCT_CATEGORIES'
export const UPDATE_PRODUCT_CATEGORY_ID_LIST = 'products/UPDATE_PRODUCT_CATEGORY_ID_LIST'
export const UPDATE_SELECTED_PRODUCT_CATEGORY_ID = 'products/UPDATE_SELECTED_PRODUCT_CATEGORY_ID'


export const UPDATE_BUY_PRODUCTS = 'shoppingProgress/UPDATE_BUY_PRODUCTS'


export const UPDATE_SHOPPING_COUNT = 'headerBar/UPDATE_SHOPPING_COUNT'