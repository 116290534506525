import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
import shoppingCompleted from '../modules/shoppingCompleted';


class ShoppingCompletedPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
  	return (
	    <div className="container-fluid padding0" id="page-shoppingCompleted">
      <Helmet>
        <title>鄧師傅功夫菜</title>
        <meta name="description" content="飄香高雄35載，滷豬腳年銷售超越50座臺北101大樓高度! 馳名海內外，獲得媒體一致推薦。Chef Teng founded in 1984, Taiwan, is famous for signature stewed pork knuckles and has won awards and recognition from news media around the world." />
      </Helmet>
        <headerBar.container/>

        {/* <div className="ShoppingProgressBannerTitle">
          <p className="center">購物流程</p>
        </div>         */}
        <div className="row TitleBox">
          <p className="col-auto mr-auto Title">購物流程</p>
          <div className="col-auto DirectionTitle">首頁 / 購物流程</div>
        </div>

        <shoppingCompleted.container/>

        <footer.container/>
	    </div>
	  )
  }
}

export default ShoppingCompletedPageComponent;
