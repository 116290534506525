import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
import resetPasswordWithToken from '../modules/resetPasswordWithToken';
// import * as api from '../utils/api';


class resetPasswordWithTokenPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: ''
    };
  }
  componentDidMount() {
    // api.rImage('resetPw').then(json => this.setState({ imgSrc: json.url }));
  }  
  render() {
  	return (
	    <div id="page-resetPasswordWithToken">
        <headerBar.container/>

        <div>
          <img src={this.state.imgSrc} alt=""/>
        </div>

        <resetPasswordWithToken.container
          token={this.props.match.params.token}
        />

        <footer.container/>
	    </div>
	  )
  }
}

export default resetPasswordWithTokenPageComponent;
