import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import * as api from '../../utils/api';


export const doUpdateMedias = createAction(types.UPDATE_MEDIAS)
export const doUpdateMediaIdList = createAction(types.UPDATE_MEDIA_ID_LIST)
export const doUpdateCurrentMedia = createAction(types.UPDATE_CURRENT_MEDIA)

export function doLoadMedia() {
  return dispatch => {
    api.rMedia().then(result => {
      const mediaIdList = [];
      const medias = {};
      result.forEach(media => {
        mediaIdList.push(`${media.mediaId}`);
        medias[media.mediaId] = media;
      })
      dispatch(doUpdateMedias({ medias }));      
      dispatch(doUpdateMediaIdList({ mediaIdList }));
    }).catch((errMsg) => {
      console.log(errMsg);
    })    
  }
}
