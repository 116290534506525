import React, { Component } from 'react';
import ecpay2 from '../modules/ecpay2';


class ShoppingEcpay2PageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
  	return (
	    <div>
        <ecpay2.container/>
	    </div>
	  )
  }
}

export default ShoppingEcpay2PageComponent;
