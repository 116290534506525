import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';
import * as cookie from "../../utils/cookiesTool";


const Reducer = handleActions({
  'specials/UPDATE_SPECIALS_LIST': (state, { payload }) => {
    return state.setIn(['specialsList'], fromJS(payload));
  },
  'specials/UPDATE_BUY_SPECIALS': (state, { payload }) => {
    const now = new Date();
    cookie.setCookie('shoppingTime', now.toString());
    if (payload) {
      return state.setIn(['buySpecials'], payload);
    } else {
      return state.setIn(['buySpecials'], fromJS({}));  // for onCleanBuySpecials()
    }
  },
  'specials/UPDATE_REMOVE_SPECIAL': (state, { payload }) => {
    return state.setIn(['buySpecials'], fromJS({}));
  },
}, State);

export default Reducer;