import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
import redeems from '../modules/redeems';


class RedeemsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div id="page-redeems">
        <Helmet>
          <title>鄧師傅功夫菜-集點換購</title>
          <meta name="description" content="飄香高雄35載，滷豬腳年銷售超越50座臺北101大樓高度! 馳名海內外，獲得媒體一致推薦。Chef Teng founded in 1984, Taiwan, is famous for signature stewed pork knuckles and has won awards and recognition from news media around the world." />
        </Helmet>
        <headerBar.container />
        <div className="row TitleBox">
          <p className="col-auto m-auto Title Tab">
            <span className="titleActive">
              兌換品項
            </span>
            <span>
              <Link className="title" to={`/redeemInfo`}>
                集點須知
              </Link>
            </span>
            <span>
              <Link className="title" to={`/memberNotice`}>
                會員新制公告
              </Link>
            </span>
            <span>
              <Link className="title" to={`/memberQA`}>
                會員 Q&A
              </Link>
            </span>
            <span>
              <Link className="title" to={`/loveLighting`}>
                用愛點亮餐桌
              </Link>
            </span>
          </p>
        </div>

        <redeems.container
          categoryId={this.props.match.params.categoryId}
        />

        <footer.container />
      </div>
    )
  }
}

export default RedeemsPageComponent;
