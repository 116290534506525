import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
import orders from '../modules/orders';
import * as cookie from '../utils/cookiesTool';
// images
import image from '../images/page-memberNotice2.jpg'


class OrdersPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "order",
    };
  }
  render() {
    return (
      <div id="page-orders">
        <Helmet>
          <title>鄧師傅功夫菜</title>
          <meta name="description" content="飄香高雄35載，滷豬腳年銷售超越50座臺北101大樓高度! 馳名海內外，獲得媒體一致推薦。Chef Teng founded in 1984, Taiwan, is famous for signature stewed pork knuckles and has won awards and recognition from news media around the world." />
        </Helmet>
        <headerBar.container />

        {/* <div className="MyAccountBannerTitle">
        <p className="center">我的帳戶</p>
      </div>         */}
        <div className="row TitleBox">
          <p className="col-auto mr-auto Title">我的帳戶</p>
          <div className="col-auto DirectionTitle">首頁 / 我的帳戶</div>
        </div>

        <div className="OrdersContent">
          <div className="row">
            <div className="col-md-4">
              <div className="redeem">
                會員累積點數：{this.props.memberRedeem} 點
              </div>
              <div className="OrdersItem">
                <h1>帳號管理</h1>
                <button type='button' onClick={() => this.setState({ tab: 'order' })}>
                  <p className={`${this.state.tab === 'order' ? 'text-danger' : ''}`}>歷史訂單</p>
                </button>
                <Link to={`/profile`}>
                  <p>修改會員資料</p>
                </Link>
                <Link to={`/memberInfo`} target="_blank">
                  <p>會員須知</p>
                </Link>
                <button type='button' onClick={() => this.setState({ tab: 'memberPost' })}>
                  <p className={`${this.state.tab === 'memberPost' ? 'text-danger' : ''}`}>會員新制公告</p>
                </button>
                {
                  cookie.getCookie('loginMethod') === 'ACCOUNT'
                  &&
                  <Link to={`/password/reset`}>
                    <p>修改密碼</p>
                  </Link>
                }
                {/*
                <Link to={``}>
                  <p>購物金明細</p>
                </Link>
                */}
                {/*
                <Link to={`/coupons`}>
                  <p>優惠券明細</p>
                </Link>
                */}
                {/* 
                <div>
                  <p>購物金</p>
                  <p></p>
                  <p>點</p>
                </div>
                */}
              </div>
            </div>
            <div className="col-md-8">
              {
                this.state.tab === 'order' ?
                  <orders.container />
                : this.state.tab === 'memberPost' ?
                  <div className='pt-3'><a href='/memberQA'><img style={{ width: '100%' }} src={image} alt="鄧師傅新制" /></a></div>
                :
                  null
              }
            </div>
          </div>
        </div>

        <footer.container />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  memberRedeem: headerBar.selectors.getMemberRedeem(state)
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersPageComponent);