import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import OrdersComponent from './component';


const mapStateToProps = (state) => ({
  orderList: selectors.getOrderList(state),
  searchText: selectors.getSearchText(state),
  statusFilter: selectors.getStatusFilter(state),
  dateFilter: selectors.getDateFilter(state),
  selectedOrderId: selectors.getSelectedOrderId(state),
});

const mapDispatchToProps = dispatch => ({
  onIsLoggedIn: (history) => {
    dispatch(actions.doIsLoggedIn(history));
  },    
  onChangeStatusFilter: (statusFilter) => {
    dispatch(actions.doChangeStatusFilter({ statusFilter }));
  },
  onChangeDateFilter: (dateFilter) => {
    dispatch(actions.doChangeDateFilter({ dateFilter }));
  },
  onChangeSearchText: (searchText) => {
    dispatch(actions.doChangeSearchText({ searchText }));
  },  
  onLoadOrdersPage: () => {
  	dispatch(actions.doLoadOrdersPage());
  },
  onClickOrder: (history, orderId) => {
    dispatch(actions.doClickOrder(history, orderId));
  }    
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersComponent);

 
