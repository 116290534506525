import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import ShoppingProgressComponent from './component';
import * as specialsActions from '../specials/actions';
import * as headerBarSelectors from '../headerBar/selectors';

const mapStateToProps = (state) => ({
  isLoggedIn: selectors.getIsLoggedIn(state),
  isCompleted: selectors.getIsCompleted(state),
  currentStep: selectors.getCurrentStep(state),

  buySpecials: selectors.getBuySpecials(state),
  buyProducts: selectors.getBuyProducts(state),
  buyRedeems: selectors.getBuyRedeems(state),
  products: selectors.getProducts(state),
  canShippingSelf: selectors.getCanShippingSelf(state),
  canShippingDelivery: selectors.getCanShippingDelivery(state),
  canShippingTC: selectors.getCanShippingTC(state),
  shippingSelfDateList: selectors.getShippingSelfDateList(state),
  shippingDeliveryDateList: selectors.getShippingDeliveryDateList(state),
  shippingTCDateList: selectors.getShippingTCDateList(state),
  totalAmount: selectors.getTotalAmount(state),
  totalRedeem: selectors.getTotalRedeem(state),

  storeIdNames: selectors.getStoreIdNames(state),
  deliveryMethod: selectors.getDeliveryMethod(state),
  deliveryDate: selectors.getDeliveryDate(state),
  deliveryTime: selectors.getDeliveryTime(state),
  storeId: selectors.getStoreId(state),
  storeName: selectors.getStoreName(state),
  
  purchaserName: selectors.getPurchaserName(state),
  purchaserGender: selectors.getPurchaserGender(state),
  purchaserEmail: selectors.getPurchaserEmail(state),
  purchaserPhone: selectors.getPurchaserPhone(state),
  purchaserAddress: selectors.getPurchaserAddress(state),
  purchaserCity: selectors.getPurchaserCity(state),
  
  asPurchaserData: selectors.getAsPurchaserData(state),
  deliveryName: selectors.getDeliveryName(state),
  deliveryGender: selectors.getDeliveryGender(state),
  deliveryPhone: selectors.getDeliveryPhone(state),
  deliveryAddress: selectors.getDeliveryAddress(state),  
  deliveryCity: selectors.getDeliveryCity(state), 

  paymentMethod: selectors.getPaymentMethod(state),  
  tcatCity: selectors.getTCatCity(state),  
  tcatStation: selectors.getTCatStation(state),  
  tcatStationName: selectors.getTCatStationName(state),
  couponCode: selectors.getCouponCode(state),
  couponId: selectors.getCouponId(state),
  couponContent: selectors.getCouponContent(state),
  gift: selectors.getGift(state),
  giftId: selectors.getGiftId(state),  

  invoiceType: selectors.getInvoiceType(state),
  invoiceUbn: selectors.getInvoiceUbn(state),
  invoiceTitle: selectors.getInvoiceTitle(state),

  greetings: selectors.getGreetings(state),
  note: selectors.getNote(state),     

  errorMessage: selectors.getErrorMessage(state),
  isAgreeTerms: selectors.getIsAgreeTerms(state), 
  shippingCost: selectors.getShippingCost(state),
  couponDiscount: selectors.getCouponDiscount(state),
  promotionDiscount: selectors.getPromotionDiscount(state),
  deduction: selectors.getDeduction(state),
  balanceDue: selectors.getBalanceDue(state),

  useRedeem: selectors.getUseRedeem(state),
  newRedeem: selectors.getNewRedeem(state),
  memberRedeem: headerBarSelectors.getMemberRedeem(state),

  orderId: selectors.getOrderId(state),

  TaiwanCity: selectors.getTaiwanCity(state),
  islands: selectors.getIslands(state),
  TCatStations: selectors.getTCatStations(state),
});

const mapDispatchToProps = dispatch => ({
  onIsLoggedIn: (history) => {
    dispatch(actions.doIsLoggedIn(history));
  },
  onUpdateBuyProductQuantity: (productId, buyQuantity) => {
    dispatch(actions.doUpdateBuyProductQuantity(productId, buyQuantity));
  },
  onClickRemoveBuyProduct: (productId) => {
    dispatch(actions.doClickRemoveBuyProduct(productId, 0));
  },    
  onLoadStoreIdNames: () => {
    dispatch(actions.doLoadStoreIdNames());
  },
  onLoadTaiwanCity: () => {
    dispatch(actions.doLoadTaiwanCity());
  },    
  onUpdateShoppingData: (value, type) => {
    dispatch(actions.doUpdateShoppingData({ value, type }));
  },      
  onClickAsPurchaserData: (isAsPurchaserData) => {
    dispatch(actions.doClickAsPurchaserData(isAsPurchaserData));
  },       
  onClickCheckCoupon: () => {
    dispatch(actions.doCheckCoupon());
  },        
  onOrderPreprocessing: () => {
    dispatch(actions.doOrderPreprocessing());
  },
  onClickSubmitOrder: (history) => {
    dispatch(actions.doClickSubmitOrder(history));
  },
  onInitOrderData: () => {
    dispatch(actions.doInitOrderData());
  },
  onUpdateBuyProductsQty: (specialsId, buyProductsListIdx, buyProductsQty) => {
    dispatch(specialsActions.doUpdateBuyProductsQty(specialsId, buyProductsListIdx, buyProductsQty));
  },
  onDeleteBuyProducts: (specialsId, buyProductsListIdx) => {
    dispatch(specialsActions.doDeleteBuyProducts(specialsId, buyProductsListIdx));
  },
  onCleanBuySpecials: () => {
    dispatch(specialsActions.doUpdateBuySpecials({}));
  },
  onUpdateBuyRedeemQuantity: (productId, buyQuantity) => {
    dispatch(actions.doUpdateBuyRedeemQuantity(productId, buyQuantity));
  },
  onClickRemoveBuyRedeem: (productId) => {
    dispatch(actions.doClickRemoveBuyRedeem(productId, 0));
  },
  onLoadTCatStations: () => {
    dispatch(actions.doLoadTCatStations());
  },
  onChangeTCatCity: (CityID) => {
    dispatch(actions.doChangeTCatCity(CityID));
  },
  onChangeTCatStation: (StationID) => {
    dispatch(actions.doChangeTCatStation(StationID));
  },
  onCleanTCatStationInfo: () => {
    dispatch(actions.doCleanTCatStationInfo());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShoppingProgressComponent);