import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
import aboutUs from '../modules/aboutUs';
import media from '../modules/media';
import featured from '../modules/featured';

// images
// import mainBanner from '../images/mainBanner.jpg'
// import mainBrand from '../images/mainBrand.png'
// import mainNews from '../images/mainNews.png'
// import mainStore from '../images/mainStore.png'
// import mainShopping from '../images/mainShopping.png'
// import mainKungfuNoodle from '../images/mainKungfuNoodle.png'
// import mainProductHistory from '../images/mainProductHistory.png'
// import mainFansDivider from '../images/mainFansDivider.png'
import logoBanner from '../images/logo_banner.png'
import btnDown from '../images/btn_Down.png'
import DecorativeLine from '../images/DecorativeLine.svg'
import TitleMedia from '../images/title_media.svg'
import Adv_photo_01 from '../images/Adv_photo_01_240603.webp'
import Adv_photo_02 from '../images/Adv_photo_02_240603.webp'

class MainPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  onClickScrollToBottom() {
    const node = ReactDOM.findDOMNode(this.bottom);
    node.scrollIntoView({ behavior: "smooth" });
  }
  render() {
    return (
      <div id="page-main">
        <Helmet>
          <title>鄧師傅功夫菜</title>
          <meta name="description" content="飄香高雄35載，滷豬腳年銷售超越50座臺北101大樓高度! 馳名海內外，獲得媒體一致推薦。Chef Teng founded in 1984, Taiwan, is famous for signature stewed pork knuckles and has won awards and recognition from news media around the world." />
        </Helmet>
        <headerBar.container />
        
        <Link to={`/products`}>
          <div className="mainBanner">
            {/*}
            <img className="btnDown" src={btnDown} alt="鄧師傅推薦" onClick={() => this.onClickScrollToBottom()} />
            {*/}
          </div>
        </Link>

        <aboutUs.container />

        <div className="">
          <div className="row Adv_box">
            <div className="col-12 col-md-6 AdvContentBox">
              <div className="AdvImgBox">
                <Link to={`/product/464`}>
                  <img className="img-fluid rounded mx-auto d-block" src={Adv_photo_01} alt="鄧師傅推薦" />
                  {/* <div className="AdvTextBox">
                    <p className="AdvTitle">【超豪華團圓組】</p>
                    <p className="AdvText">饕客首選：小火慢烤精品級台灣豬肉【究好豬】豬肋排，肉質軟嫩彈牙！搭配傳統好滋味【在家開飯】小菜，好友狂歡食刻，給您飯店級的美味饗宴！數量有限，售完為止。</p>
                    <div className="AdvBtn-wrap">
                      <Link to={`/product/344`}><button className="AdvBtn" type="button">立即訂購</button></Link>
                    </div>
                  </div> */}
                </Link>
              </div>
            </div>

            <div className="col-12 col-md-6 AdvContentBox">
              <div className="AdvImgBox">
                <Link to={`/`}>
                  <img className="img-fluid rounded mx-auto d-block" src={Adv_photo_02} alt="鄧師傅推薦" />
                  {/* <div className="AdvTextBox">
                    <p className="AdvTitle">免運優惠限台灣本島單一地點配送</p>
                    <p className="AdvText">家庭料理百搭新選擇！精選國內眾多星級餐廳與名廚選用的台灣知名品牌【究好豬】五花肉製作，拌飯、拌麵、拌青菜，快速復熱美味上桌！數量有限，售完為止。</p>
                    <div className="AdvBtn-wrap">
                      <Link to={`/products`}><button className="AdvBtn" type="button">立即訂購</button></Link> 
                    </div>
                  </div> */}
                </Link>
              </div>
            </div>
          </div>
        </div>









        <div className="blockGole">
        </div>






        <div className="mediaCoverage">
          <div className="container-fluid">
            <div className="row">
              <div className="col"><img className="mainPage_Title rounded mx-auto d-block" src={TitleMedia} alt="" /></div>
            </div>
            <media.container />
          </div>
        </div>

        <div className="FBBOX">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-8 col-xl-6">
                <div className="use-flexbox row">
                  <div className="col-md-12"><p className="FBText_1">快來粉絲團按讚獲得最新消息吧！</p></div>
                  <div className="col-md-12"><p className="FBText_2">{/*}(加入Line@消費積點又可以抽免費兌換券){*/}</p></div>
                </div>
              </div>
              <div className="col-12 col-sm-4 col-xl-6">
                <div className="col-md-12">
                  <div className="fb-page" data-href="https://www.facebook.com/ChefTengRG/" data-tabs="timeline,messages" data-width="500" data-height="500" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false"><blockquote cite="https://www.facebook.com/ChefTengRG/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/ChefTengRG/">鄧師傅功夫菜</a></blockquote></div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <footer.container ref={(el) => { this.bottom = el }} />
      </div>
    )
  }
}

export default MainPageComponent;

(function (d, s, id) {
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s); js.id = id;
  js.src = 'https://connect.facebook.net/zh_TW/sdk.js#xfbml=1&version=v2.11';
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));
