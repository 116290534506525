import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import LoginComponent from './component';


const mapStateToProps = (state) => ({
  account: selectors.getAccount(state),
  password: selectors.getPassword(state),
  errMessage: selectors.getErrMessage(state),
  showLoading: selectors.getShowLoading(state)
});

const mapDispatchToProps = dispatch => ({
  onIsLoggedIn: (history) => {
    dispatch(actions.doIsLoggedIn(history));
  },      
  onSubmitLogin: (history) => {
    dispatch(actions.doSubmitLogin(history));
  },
  onEditLogin: (value, type) => {
    dispatch(actions.doEditLogin({ value, type }));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginComponent);