import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { message, Modal } from 'antd';
// images
import toShoppingCartIcon from '../../images/toShoppingCart.svg'
import minusIcon from '../../images/minus.svg'; // -
import plusIcon from '../../images/plus.svg'; // +

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});


class SpecialsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buyingSepcials: {},
    };
  }
  componentDidMount() {
    this.props.onLoadSpecials();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.specialsList.size === 0 && this.props.specialsList.size > 0) {
      const buyingSepcials = {};
      this.props.specialsList.forEach(specials => 
        buyingSepcials[specials.getIn(['id'])] = {
          'buyProductIdList': [''],
          'buyProducts': {'': 1},
          'buyProductsQty': 1
        }
      );
      this.setState({ buyingSepcials });
    }
  }
  onAddProducts = specials => {
    const buyingSepcials = JSON.parse(JSON.stringify(this.state.buyingSepcials));

    if (buyingSepcials[specials.getIn(['id'])].buyProducts.hasOwnProperty('')) {
      message.error(`請先選擇口味後再新增`);
    } else if (Object.values(buyingSepcials[specials.getIn(['id'])].buyProducts).reduce((a, b) => a+b) >= specials.getIn(['qty'])) {
      message.error(`選取口味已超過${specials.getIn(['qty'])}入`);      
    } else {
      buyingSepcials[specials.getIn(['id'])].buyProductIdList.push('');
      buyingSepcials[specials.getIn(['id'])].buyProducts[''] = 1;

      this.setState({ buyingSepcials });
    }
  }
  onClickDeleteBuyProducts = (specialsId, buyProductId) => {
    const buyingSepcials = JSON.parse(JSON.stringify(this.state.buyingSepcials));

    const buyProductIdListIdx = buyingSepcials[specialsId].buyProductIdList.indexOf(buyProductId);
    buyingSepcials[specialsId].buyProductIdList.splice(buyProductIdListIdx, 1);
    delete buyingSepcials[specialsId].buyProducts[buyProductId];

    this.setState({ buyingSepcials });
  }
  onChangebuyProductId = (specialsId, buyProductIdListIdx, productId) => {
    const buyingSepcials = JSON.parse(JSON.stringify(this.state.buyingSepcials));

    // 先清掉之前所選的 product
    let originalBuyProductId = buyingSepcials[specialsId].buyProductIdList[buyProductIdListIdx];
    delete buyingSepcials[specialsId].buyProducts[originalBuyProductId];
    // 再加上新的 product
    buyingSepcials[specialsId].buyProductIdList[buyProductIdListIdx] = productId;
    buyingSepcials[specialsId].buyProducts[productId] = 1;

    this.setState({ buyingSepcials });
  }
  onChangeBuyProductQty = (specials, buyProductId, buyProductQty) => {
    if (buyProductQty >= 1) {
      const buyingSepcials = JSON.parse(JSON.stringify(this.state.buyingSepcials));

      buyingSepcials[specials.getIn(['id'])].buyProducts[buyProductId] = buyProductQty;

      if (Object.values(buyingSepcials[specials.getIn(['id'])].buyProducts).reduce((a, b) => a+b) > specials.getIn(['qty'])) {
        message.error(`選取口味不得超過${specials.getIn(['qty'])}入`);
      } else {
        this.setState({ buyingSepcials });
      }
    }
  }
  onChangeBuyProductsQty = (specialsId, buyProductsQty) => {
    if (buyProductsQty >= 1) {
      const buyingSepcials = JSON.parse(JSON.stringify(this.state.buyingSepcials));

      buyingSepcials[specialsId].buyProductsQty = buyProductsQty;

      this.setState({ buyingSepcials });
    }
  }
  onClikcAddToShoppingCart = specials => {
    const buyingSepcials = JSON.parse(JSON.stringify(this.state.buyingSepcials));

    if (buyingSepcials[specials.getIn(['id'])].buyProducts.hasOwnProperty('')) {
      message.error(`尚有未選取的口味`);
    } else if (Object.values(buyingSepcials[specials.getIn(['id'])].buyProducts).reduce((a, b) => a+b) !== specials.getIn(['qty'])) {
      message.error(`選取口味未達${specials.getIn(['qty'])}入`);      
    } else {
      this.props.onAddToShoppingCart(specials, buyingSepcials[specials.getIn(['id'])].buyProducts, buyingSepcials[specials.getIn(['id'])].buyProductsQty);
      buyingSepcials[specials.getIn(['id'])] = {
        'buyProductIdList': [''],
        'buyProducts': {'': 1},
        'buyProductsQty': 1
      }
      this.setState({ buyingSepcials })
    }
  }
  render() {
    const {
      buyingSepcials
    } = this.state;
    return (
      <div className="container-fluid SpecialsContent">
        {
          this.props.specialsList && this.props.specialsList.map(specials => 
            <div className="specialsBox" key={`specials-${specials.getIn(['id'])}`}>
              <div className="row">
                <div className="col-12">
                  <img className="img-fluid rounded mx-auto d-block SpecialsImg" src={window.innerWidth < 768 ? specials.getIn(['mBanner']) : specials.getIn(['banner'])} alt="" />
                  <div className="row">
                    <p className="specialsName pl-3 pr-3 mt-2 w-100">
                      { specials.getIn(['name']) }
                      <span className="text-danger">{ `$${specials.getIn(['price'])}` }</span> 
                    </p>
                    <div className="DescriptionBox w-100">
                      <p className="pull-right pl-3 pr-3">
                      { specials.getIn(['description']) }
                      </p>
                    </div>
                  </div>

                  <div className="row specialsCombo">
                    {
                      buyingSepcials[specials.getIn(['id'])] && buyingSepcials[specials.getIn(['id'])].buyProductIdList.map((buyProductId, buyProductIdListIdx) =>
                        <div className="col-12 col-sm-6 mb-3" key={`specials-buyproduct-${buyProductIdListIdx}`}>
                          <div className="row">
                            {
                              (buyingSepcials[specials.getIn(['id'])].buyProductIdList.length > 1 || buyProductIdListIdx !== 0)
                              &&
                              <div className="col-1 pr-0">
                                <button className="CheckSpecialItemDelete" type="button" onClick={e => this.onClickDeleteBuyProducts(specials.getIn(['id']), buyProductId)}>刪</button>
                              </div>
                            }                                                      
                            <div className={buyingSepcials[specials.getIn(['id'])].buyProductIdList.length > 1 || buyProductIdListIdx !== 0 ? 'col-7' : 'col-8'}>
                              <select className="specialsSelect" value={buyProductId} onChange={e => this.onChangebuyProductId(specials.getIn(['id']), buyProductIdListIdx, e.target.value)}>
                                <option value="" disabled>請選擇口味</option>
                                {
                                  specials.getIn(['productIdList']).map(productId => 
                                    <option key={`specials-product-${productId}`} value={productId} disabled={buyingSepcials[specials.getIn(['id'])].buyProducts.hasOwnProperty(productId) ? true : null}>{ specials.getIn(['products', productId]) }</option>
                                  )
                                }
                              </select>
                            </div>
                            <div className="col-4 pl-0">
                              <div className="d-flex align-items-center QuantityBar specialsNumberBox">
                                <button className="Minus" type="button" onClick={e => this.onChangeBuyProductQty(specials, buyProductId, buyingSepcials[specials.getIn(['id'])].buyProducts[buyProductId]-1)}><img src={minusIcon} alt="" /></button>
                                <input className="specialsNumber" type="number" name="quantity" value={buyingSepcials[specials.getIn(['id'])].buyProducts[buyProductId]} disabled/>
                                <button className="Plus" type="button" onClick={e => this.onChangeBuyProductQty(specials, buyProductId, buyingSepcials[specials.getIn(['id'])].buyProducts[buyProductId]+1)}><img src={plusIcon} alt="" /></button>                   
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }
                    <div className="col-12 col-sm-6 mb-3">
                      <div className="row">
                        <div className="col-8 CheckAddText">
                          如需新增口味，請點選新增口味
                        </div>
                        <div className="col-4 pl-0">
                          <button className="CheckAddBtn w-100" type="button" onClick={e => this.onAddProducts(specials)}>新增口味</button>                   
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3 specialsSet">
                    <div className="col-md-4 col-8 CheckAddText">
                      { specials.getIn(['name']) }
                    </div>
                    <div className="col-md-2 col-4 pl-0">
                      <div className="d-flex align-items-center QuantityBar specialsNumberBox">
                        <button className="Minus" type="button" onClick={e => this.onChangeBuyProductsQty(specials.getIn(['id']), buyingSepcials[specials.getIn(['id'])].buyProductsQty-1)}><img src={minusIcon} alt="" /></button>
                        <input className="specialsNumber" type="number" name="quantity" value={buyingSepcials[specials.getIn(['id'])] ? buyingSepcials[specials.getIn(['id'])].buyProductsQty : 0} disabled/>
                        <button className="Plus" type="button" onClick={e => this.onChangeBuyProductsQty(specials.getIn(['id']), buyingSepcials[specials.getIn(['id'])].buyProductsQty+1)}><img src={plusIcon} alt="" /></button>                   
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <button className="CheckCartBtn w-100" onClick={e => this.onClikcAddToShoppingCart(specials)}>加入購物車</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
    )
  }
}

export default withRouter(SpecialsComponent);