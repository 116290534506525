import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class MediaComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  componentDidMount() {
    this.props.onLoadMedia();
  }
  render() {
    const {
      mediaIdList, medias,
      onChangeMedia
    } = this.props;
    return (
      <div className="rowBox row">
        {
          (mediaIdList && medias)
          &&
          mediaIdList.map(mediaId => (
            <div className="colBox col-4 col-lg-2" key={mediaId} onClick={(event) => onChangeMedia(medias.getIn([mediaId, "mediaName"]))}>
              <Link to="/news/">
                <img className="imgBox rounded mx-auto d-block" src={medias.getIn([mediaId, "mediaImage"])} alt="" />
              </Link>
            </div>
          ))
        }
      </div>
    )
  }
}

export default MediaComponent;