import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import ShoppingCartComponent from './component';
import * as specialsActions from '../specials/actions';
import * as headerBarSelectors from '../headerBar/selectors';
import * as loginSelectors from '../login/selectors';

const mapStateToProps = (state) => ({
  buySpecials: selectors.getBuySpecials(state),
  buyProducts: selectors.getBuyProducts(state),
  products: selectors.getProducts(state),
  buyRedeems: selectors.getBuyRedeems(state),
  totalAmount: selectors.getTotalAmount(state),
  totalRedeem: selectors.getTotalRedeem(state),
  memberRedeem: headerBarSelectors.getMemberRedeem(state),
  memberId: loginSelectors.getMemberId(state),
});

const mapDispatchToProps = dispatch => ({
  onIsLoggedIn: (history) => {
    dispatch(actions.doIsLoggedIn(history));
  },
  onLoadProducts: () => {
    dispatch(actions.doLoadProducts());
  },
  onUpdateBuyProductQuantity: (productId, buyQuantity) => {
    dispatch(actions.doUpdateBuyProductQuantity(productId, buyQuantity));
  },
  onClickRemoveBuyProduct: (productId) => {
    dispatch(actions.doClickRemoveBuyProduct(productId, 0));
  },
  onUpdateShoppingData: (value, type) => {
    dispatch(actions.doUpdateShoppingData({ value, type }));
  },
  onClickProgress: (history, value) => {
    dispatch(actions.doClickProgress({ history, value }));
  },
  onUpdateBuyProductsQty: (specialsId, buyProductsListIdx, buyProductsQty) => {
    dispatch(specialsActions.doUpdateBuyProductsQty(specialsId, buyProductsListIdx, buyProductsQty));
  },
  onDeleteBuyProducts: (specialsId, buyProductsListIdx) => {
    dispatch(specialsActions.doDeleteBuyProducts(specialsId, buyProductsListIdx));
  },
  onUpdateBuyRedeemQuantity: (productId, buyQuantity) => {
    dispatch(actions.doUpdateBuyRedeemQuantity(productId, buyQuantity));
  },
  onClickRemoveBuyRedeem: (productId) => {
    dispatch(actions.doClickRemoveBuyRedeem(productId, 0));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShoppingCartComponent);