import { createSelector } from 'reselect';

export const getShowUserDropDown = state => state.getIn(['headerBar', 'showUserDropDown']);
export const getShoppingCount = state => state.getIn(['headerBar', 'shoppingCount']);
export const getBuyProducts = state => state.getIn(['shoppingProgress', 'buyProducts']);
// export const getShoppingCount = createSelector(
//   [getBuyProducts],
//   (buyProducts) => buyProducts.keySeq().size
// )

// export const getPassword = state => state.getIn(['login', 'password']);
// export const getErrMessage = state => state.getIn(['login', 'errMessage']);

export const getMemberRedeem = state => state.getIn(['login', 'memberRedeem']);
export const getName = state => state.getIn(['profile', 'name']);