import { createAction } from 'redux-actions';
import { message } from 'antd';
import { fromJS } from 'immutable';
import * as types from './actionTypes';
import * as api from '../../utils/api';
import * as cookie from '../../utils/cookiesTool';
import * as validation from '../../utils/validation';


const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});

export const updateSpecialsList = createAction(types.UPDATE_SPECIALS_LIST)
export function doLoadSpecials() {  
  return dispatch => {
    dispatch(updateSpecialsList([]));
    api.rSpecials().then(result => {  
      dispatch(updateSpecialsList(result));
    }).catch((errMsg) => {
      console.log(errMsg);
    })    
  }
}


export const doUpdateBuySpecials = createAction(types.UPDATE_BUY_SPECIALS)
export const doUpdateShoppingCount = createAction(types.UPDATE_SHOPPING_COUNT)
export function doAddToShoppingCart(specials, buyProducts, buyProductsQty) {  
  return (dispatch, getState) => {
    const state = getState();
    const products = state.getIn(['products', 'products']);
    const buyPackages = state.getIn(['shoppingProgress', 'buyProducts']);  // 參數名稱先更正為 buyPackages，避免與 sepcials 的 buyProducts 混淆
    const buyRedeems = state.getIn(['shoppingProgress', 'buyRedeems']);
    let buySpecials = state.getIn(['specials', 'buySpecials']);

    let existShipping = validation.getExistShipping(products, buyPackages, buyRedeems, buySpecials)
    let canShippingSelf = existShipping.canShippingSelf;
    let canShippingDelivery = existShipping.canShippingDelivery;
    let shippingSelfDateList = existShipping.shippingSelfDateList;
    let shippingDeliveryDateList = existShipping.shippingDeliveryDateList;

    if (specials.getIn(['shippingSelf']) === false) {
      canShippingSelf = false;
    }
    if (specials.getIn(['shippingDelivery']) === false) {
      canShippingDelivery = false;
    }
    // 一部份商品只允許自取、另有一部份商品只允許宅配
    if (canShippingSelf === false && canShippingDelivery === false) {
      message.error('購物車已包含其它限期商品，並與此商品的取貨方式衝突，請分開訂購');
      return;
    }
    // 已存在限定自取日期的商品，新加入的限期商品至少要有一天重疊
    if (canShippingSelf === true && shippingSelfDateList.length > 0 && specials.getIn(['shippingSelfDateList']).size > 0) {
      if (!shippingSelfDateList.some(shippingDate => specials.getIn(['shippingSelfDateList']).indexOf(shippingDate) !== -1)) {
        message.error('購物車已包含其它限期商品，並與此商品的取貨日期衝突，請分開訂購');
        return;
      }
    }
    // 已存在限定宅配日期的商品，新加入的限期商品至少要有一天重疊
    if (canShippingDelivery === true && shippingDeliveryDateList.length > 0 && specials.getIn(['shippingDeliveryDateList']).size > 0) {
      if (!shippingDeliveryDateList.some(shippingDate => specials.getIn(['shippingDeliveryDateList']).indexOf(shippingDate) !== -1)) {
        message.error('購物車已包含其它限期商品，並與此商品的取貨日期衝突，請分開訂購');
        return;
      }
    }

    buyProducts['qty'] = buyProductsQty;
    if (buySpecials.hasIn([specials.getIn(['id'])])) {
      let buyProductsList = buySpecials.getIn([specials.getIn(['id']), 'buyProductsList']);
      buyProductsList = buyProductsList.push(fromJS(buyProducts));
      buySpecials = buySpecials.setIn([specials.getIn(['id']), 'buyProductsList'], buyProductsList);
    } else {
      specials = specials.setIn(['buyProductsList'], fromJS([buyProducts]));
      buySpecials = buySpecials.setIn([specials.getIn(['id'])], specials);
    }

    dispatch(doUpdateBuySpecials(buySpecials));
    dispatch(doUpdateShoppingCount({ count: buyProductsQty }));
    message.info('已加入購物車');
  }
}


export function doUpdateBuyProductsQty(specialsId, buyProductsListIdx, buyProductsQty) {  
  return (dispatch, getState) => {
    const state = getState();
    let buySpecials = state.getIn(['specials', 'buySpecials']);
    let buyProducts = buySpecials.getIn([specialsId, 'buyProductsList', buyProductsListIdx]);
    if (buyProductsQty >= 1) {
      const originalBuyProductsQty = buyProducts.getIn(['qty']);      
      buyProducts = buyProducts.setIn(['qty'], buyProductsQty);
      buySpecials = buySpecials.setIn([specialsId, 'buyProductsList', buyProductsListIdx], buyProducts);

      dispatch(doUpdateBuySpecials(buySpecials));
      dispatch(doUpdateShoppingCount({ count: buyProductsQty-originalBuyProductsQty }));
    }
  }
}


export function doDeleteBuyProducts(specialsId, buyProductsListIdx) {  
  return (dispatch, getState) => {
    const state = getState();
    let buySpecials = state.getIn(['specials', 'buySpecials']);
    let buyProductsList = buySpecials.getIn([specialsId, 'buyProductsList']);
    let removeBuyProductsQty = buyProductsList.getIn([buyProductsListIdx, 'qty']);
    buyProductsList = buyProductsList.delete(buyProductsListIdx);
    if (buyProductsList.size > 0) {
      buySpecials = buySpecials.setIn([specialsId, 'buyProductsList'], buyProductsList);
    } else {
      buySpecials = buySpecials.delete(specialsId);
    }

    dispatch(doUpdateBuySpecials(buySpecials));
    dispatch(doUpdateShoppingCount({ count: -removeBuyProductsQty }));
  }
}