import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
import news from '../modules/news';

class NewsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="container-fluid padding0" id="page-news">
        <Helmet>
          <title>鄧師傅功夫菜-媒體報導</title>
          <meta name="description" content="飄香高雄35載，滷豬腳年銷售超越50座臺北101大樓高度! 馳名海內外，獲得媒體一致推薦。Chef Teng founded in 1984, Taiwan, is famous for signature stewed pork knuckles and has won awards and recognition from news media around the world." />
        </Helmet>
        <headerBar.container />

        <div className="row TitleBox">
          <p className="col-auto m-auto Title Tab">
            <span>
              <Link className="title" to={`/aboutUs`}>
                關於我們
              </Link>
            </span>
            <span>
              <Link className="title" to={`/story`}>
                品牌故事
              </Link>
            </span>
            <span>
              <Link className="title" to={`/shoppingNotes`}>
                購物須知
              </Link>
            </span>
            <span className="titleActive">
              媒體報導
            </span>
          </p>
        </div>

        <news.container />

        <footer.container />
      </div>
    )
  }
}

export default NewsPageComponent;
