import React, { Component } from 'react';
import { fromJS } from 'immutable';
import { Helmet } from 'react-helmet';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
import featured from '../modules/featured';
// images
import heatingInstructions from '../images/heatingInstructions240110.jpg'

class HeatingInstructionsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    return (
      <div id="page-heatingInstructions">
        <Helmet>
          <title>鄧師傅功夫菜-加熱說明</title>
          <meta name="description" content="飄香高雄35載，滷豬腳年銷售超越50座臺北101大樓高度! 馳名海內外，獲得媒體一致推薦。Chef Teng founded in 1984, Taiwan, is famous for signature stewed pork knuckles and has won awards and recognition from news media around the world." />
        </Helmet>
        <headerBar.container />

        {/* <div className="KunfuBannerTitle">
          <p className="center">煨功夫麵</p>
        </div>  */}
        {/* <div className="row TitleBox">
          <p className="col-auto mr-auto Title">加熱說明</p>
          <p className="col-auto DirectionTitle">首頁 / 加熱說明</p>
        </div> */}

        <div className="heating">
          <div className="row heatingItem">
            {/* <div className="col-12 heatingTitle"><h1 className="pl-3">加熱時間（步驟）</h1></div> */}
            <div className="col-md-12 col-12">
              <img src={heatingInstructions} className="img-fluid" alt="加熱說明" />
            </div>
          </div>
          <div className="row heatingItem">
            <div className="col-md-6 col-12">
              {/* <div className="col-12 heatingTitle"><h1 className="pl-3">獨享雞湯之電鍋復熱篇</h1></div> */}
              <div className="col-12 mb-3 video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/HMFWJZ3p1DU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="col-12 mb-3 video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/DFloAu13Iy0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="col-12 mb-3 video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/aFqvkTcvGHo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="col-12 mb-3 video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/IuKenyGO7YM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="col-12 mb-3 video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/aRJBzpLWKU8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="col-12 mb-3 video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/EhZyWfKObNI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="col-12 mb-3 video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/poPOPNu3LLw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
            <div className="col-md-6 col-12">
              {/* <div className="col-12 heatingTitle"><h1 className="pl-3">獨享雞湯之瓦斯爐復熱篇</h1></div> */}
              <div className="col-12 mb-3 video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/reDh1xrUFDc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="col-12 mb-3 video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/MRQdCdRXwQI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="col-12 mb-3 video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/PIrkvoqjXTU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="col-12 mb-3 video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/YxgwiLcE9Fc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="col-12 mb-3 video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/5L-qFi-Z4_A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="col-12 mb-3 video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/z6dNfn7NYwM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HeatingInstructionsPageComponent;
