import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import * as api from '../../utils/api';
import * as cookie from '../../utils/cookiesTool';
import * as config from '../../config'


export const doShowUserDorpDown = createAction(types.SHOW_USER_DROP_DOWN)
export function doClickUser(history) {
  return (dispatch, getState) => {
    const state = getState();
    const showUserDropDown = state.getIn(['headerBar', 'showUserDropDown']);
    const stormedToken = cookie.getCookie('stormedToken');
    if (stormedToken) {
      return dispatch(doShowUserDorpDown({ show: !showUserDropDown }));
    } else {
      history.push(`/login`)
      return dispatch(doShowUserDorpDown({ show: false }));
    }
  }
 }

export const doUpdateMemberData = createAction(types.UPDATE_MEMBER_DATA)	// login module
export function doLogout() {
	return dispatch => {
    cookie.deleteAllCookies();
    localStorage.clear();
    window.location.href = `${config.logoutPage}`;
  }
}

export const updateBuyProducts = createAction(types.UPDATE_BUY_PRODUCTS)
export const updateBuyRedeems = createAction(types.UPDATE_BUY_REDEEMS)
export const updateBuySpecials = createAction(types.UPDATE_BUY_SPECIALS)
export const updateShoppingCount = createAction(types.UPDATE_SHOPPING_COUNT);
export function doShoppingCarTimeout() {
  return dispatch => {
    const shoppingTime = cookie.getCookie('shoppingTime');
    const now = new Date();
    if (shoppingTime == '' || now - Date.parse(shoppingTime.toString()) > 3600000) {
      dispatch(updateBuyProducts());
      dispatch(updateBuyRedeems());
      dispatch(updateBuySpecials());
      dispatch(updateShoppingCount({ count: 0 }));
    }
  }
}

export const updateShoppingData = createAction(types.UPDATE_SHOPPING_DATA)