import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
import banner from '../images/page-shoppingNotes.jpg';

class ShoppingNotesPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div id="page-shoppingNotes">
        <Helmet>
          <title>鄧師傅功夫菜-購物須知</title>
          <meta name="description" content="飄香高雄35載，滷豬腳年銷售超越50座臺北101大樓高度! 馳名海內外，獲得媒體一致推薦。Chef Teng founded in 1984, Taiwan, is famous for signature stewed pork knuckles and has won awards and recognition from news media around the world." />
        </Helmet>
        <headerBar.container />

        <div className="row TitleBox">
          <p className="col-auto m-auto Title Tab">
            <span>
              <Link className="title" to={`/aboutUs`}>
                關於我們
              </Link>
            </span>
            <span>
              <Link className="title" to={`/story`}>
                品牌故事
              </Link>
            </span>
            <span className="titleActive">
              購物須知
            </span>
            <span>
              <Link className="title" to={`/news`}>
                媒體報導
              </Link>
            </span>
          </p>
        </div>

        <div className="content">
          <img src={banner} alt="鄧師傅購物須知" />
          冷凍食品官網銷售定型化契約<br/>
          <br/>
          第一條 合約目的<br/>
          本契約旨在規範使用本網站進行冷凍食品購買的顧客（以下簡稱「消費者」）與本公司之間的權利與義務。<br/>
          <br/>
          第二條 定義<br/>
          本公司：指提供冷凍食品銷售服務的公司，名稱為 [鄧師傅功夫菜]。<br/>
          消費者：透過本公司官網購買冷凍食品的個人或法人。<br/>
          商品：指本公司於官網上所展示並銷售的冷凍食品。<br/>
          <br/>
          第三條 訂購程序<br/>
          消費者於本官網完成商品選擇並提交訂單，即視為同意本契約條款。<br/>
          消費者應提供正確的聯絡資訊與地址，若因提供錯誤資訊而導致配送失敗，本公司不負責相關損失。<br/>
          <br/>
          第四條 付款條款<br/>
          消費者同意使用本公司提供之付款方式進行支付，包括信用卡、轉帳等。<br/>
          本公司保留對於不正常或可疑交易的審查與處理權，必要時將與消費者聯繫以進一步確認。<br/>
          <br/>
          第五條 配送條款<br/>
          本公司將依消費者提供的配送地址進行商品配送。<br/>
          若因不可抗力（如天災、疫情、政府規範等）導致配送延誤，本公司將盡力協助解決，但不負責任何相關損失或賠償。<br/>
          消費者收到商品後，應立即檢查商品完整性，如有損壞，需於24小時內通知本公司並提供相關證明。<br/>
          <br/>
          第六條 退換貨政策<br/>
          依《消費者保護法》規定，冷凍食品因特殊性質不適用七天鑑賞期，除商品有瑕疵、配送錯誤或其他合於法令之情形外，不接受退換貨。<br/>
          如遇符合退換貨之情形，消費者需於收到商品後[幾天]內提出申請，並提供相關證明。<br/>
          <br/>
          第七條 商品保證與責任<br/>
          本公司保證商品於出貨時為符合品質標準之產品。<br/>
          若商品因非本公司過失原因（如消費者處理不當、保存不當等）導致損壞，本公司不承擔責任。<br/>
          <br/>
          第八條 智慧財產權<br/>
          本網站所有內容（包括但不限於文字、圖片、設計等）皆屬本公司所有，未經許可不得擅自使用、轉載或修改。<br/>
          <br/>
          第九條 個人資料保護<br/>
          本公司依法保護消費者之個人資料，相關資料僅用於訂單處理、配送及相關業務聯繫。<br/>
          消費者如需查詢、更正或刪除個人資料，可隨時聯繫本公司。<br/>
          <br/>
          第十條 其他<br/>
          本契約未盡事宜，依相關法令規定辦理。<br/>
          若契約條款有任何無效或不執行之情形，其他條款仍具效力。<br/>
          <br/>
          第十一條 合約變更<br/>
          本公司保留修改本契約條款之權利，修改後條款將於本官網公告，消費者應定期瀏覽。<br/>
          
          <hr/>

          購物須知<br/>

          <ul>
            <li>一、本平台所提供之商品數量有限，請您於挑選後儘速完成訂購程序，放入購物車不代表您已完成訂購，若有其他使用者在您完成訂購程序前已經完成訂購程序，系統會通知您該筆商品已售畢，請您自購物車移除。</li>
            <li>
              二、線上刷卡成功一律自動產生電子發票；貨到付款則會開立紙本發票隨貨寄出。如需統編者與公司抬頭或需索取紙本發票，請於結帳頁面中的備註欄中註明。若於訂購時已選擇發票類型，訂單成立後即無法再換開其他類型電子發票，請於消費時確認應取得二聯（個人消費）或三聯（報帳用）發票，本公司已盡告知義務，且為配合國稅局勸止二聯換開三聯之政策，本公司有權利考量各因素後拒絕換開發票。
            </li>
            <li>
              三、目前提供付款方式有三種：
              <ol className="sub">
                <li>1. 線上匯款</li>
                <li>2. 線上刷卡（僅限台灣地區發行之VISA、Master Card、JCB信用卡） </li>
                <li>3. 宅配貨到付款（限台灣本島）</li>
                <li> ※ 配合的宅配公司為：黑貓宅急便</li>
              </ol>
            </li>
            <li>四、商品價格不含運費。運費會依據您訂購的商品數量及寄送位置不同而有所調整。若選擇至鄧師傅門市取貨，則無須付宅配費用。若選擇貨到付款，本網站目前配合的物流廠商均為最專業的宅配公司－黑貓宅急便，您可放心的將商品費用與運費一併交給宅配人員。</li>
            <li>五、宅配送貨說明：由於黑貓宅急便端午/春節假期業務量大，無法指定商品到貨時段以及日期，建議各位儘早訂購，避開高峰期間。</li>
            <li>
              六、本公司因業務上所掌握之客戶基本資料均屬機密，受個人資料保護法保護，除客戶依法行使其權利（查詢或請求閱覽、請求製給複製本、請求補充或更正、請求停止蒐集、處理或利用、請求刪除）外，本公司不對第三人揭露。 前項資料客戶同意本公司刊登，並同意本公司就申請書上內容得編印或建置客戶目錄，於合約目的範圍內為合理之處理與利用，妥善保存至服務結束為止，服務結束後由本公司銷毀及刪除。
            </li>
            <li>七、退／換貨申請：在您收到商品當日起，煩請於三日內聯繫客服人員，提出退／換貨要求(以收執聯簽收日或郵戳為憑)。若經由本公司查明新品瑕疵與事實相符者，會盡速透過電話與您連絡前往取回退貨商品。取件後宅配人員會給您貨運收據，請保留至退款完成為止。</li>
            <li>八、客服時間：請於上班時間週一至週五 9:00 ~ 17:00，(例假日及中午 12:00 ~ 13:00 暫停電話服務)，撥打客服電話：<a href="tel:0800-455445">0800-455445</a>，將由客服人員盡速為您處理相關事宜。或者是可以透過鄧師傅 <a href="https://line.me/R/ti/p/%40wtz8539j">LINE@ 官方帳號（@CHEFTENG）</a>，客服人員也能盡速協助您。</li>
          </ul>
        </div>

        <footer.container />
      </div>
    )
  }
}

export default ShoppingNotesPageComponent;
