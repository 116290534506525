export const UPDATE_PRODUCTS = 'redeems/UPDATE_PRODUCTS'
export const UPDATE_PRODUCT_ID_LIST = 'redeems/UPDATE_PRODUCT_ID_LIST'
export const UPDATE_PRODUCT_CATEGORIES = 'redeems/UPDATE_PRODUCT_CATEGORIES'
export const UPDATE_PRODUCT_CATEGORY_ID_LIST = 'redeems/UPDATE_PRODUCT_CATEGORY_ID_LIST'
export const UPDATE_SELECTED_PRODUCT_CATEGORY_ID = 'redeems/UPDATE_SELECTED_PRODUCT_CATEGORY_ID'


export const UPDATE_BUY_REDEEMS = 'shoppingProgress/UPDATE_BUY_REDEEMS'


export const UPDATE_SHOPPING_COUNT = 'headerBar/UPDATE_SHOPPING_COUNT'