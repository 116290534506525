// import { createSelector } from 'reselect';

export const getProduct = state => state.getIn(['redeem', 'product']);
export const getBuyQuantity = state => state.getIn(['redeem', 'buyQuantity']);

// export const getStores = state => state.getIn(['stores', 'stores']);
// export const getSelectedStoreId = state => state.getIn(['stores', 'selectedStoreId']);

// export const getSelectedStore = createSelector(
//   [getStores, getSelectedStoreId],
//   (stores, selectedStoreId) => stores.getIn([selectedStoreId])
// )