import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  'media/UPDATE_MEDIAS': (state, { payload }) => {
    return state.setIn(['medias'], fromJS(payload.medias));
  },
  'media/UPDATE_MEDIA_ID_LIST': (state, { payload }) => {
    return state.setIn(['mediaIdList'], fromJS(payload.mediaIdList));
  },
  'news/UPDATE_CURRENT_MEDIA': (state, { payload }) => {
    return state.setIn(['currentMedia'], payload.currentMedia);
  },
}, State);

export default Reducer;