import { createSelector } from 'reselect';
import { ORDER_STATUS } from '../../utils/constants/order';


export const getSelectedOrderId = state => state.getIn(['products', 'selectedOrderId']);
export const getAllOrderList = state => state.getIn(['orders', 'orderList']);
export const getSearchText = state => state.getIn(['orders', 'searchText']);
export const getStatusFilter = state => state.getIn(['orders', 'statusFilter']);
export const getDateFilter = state => state.getIn(['orders', 'dateFilter']);

export const getOrderList = createSelector(
  [getAllOrderList, getSearchText, getStatusFilter, getDateFilter],
  (allOrderList, searchText, statusFilter, dateFilter) => 
		allOrderList.filter(order => {
			if (dateFilter !== '0') {
				let orderDate = new Date(order.getIn(['orderDate']));
				let filterDate = new Date();
				filterDate.setMonth(filterDate.getMonth() - +dateFilter);
				if (orderDate < filterDate) {
			    return false;
			  }
			}
			if (statusFilter !== '全部狀態' && ORDER_STATUS[order.getIn(['status'])] !== statusFilter) {
				return false;
			}
			if (searchText === '') {
				return true;
			}
			if (order.getIn(['id']).toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
				return true
			}		
			if (`${order.getIn(['balanceDue'])}`.toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
				return true
			}
			if (ORDER_STATUS[order.getIn(['status'])].toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
				return true
			}
			if (order.getIn(['orderDate']).toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
				return true
			}			
			return false;
		})
)
