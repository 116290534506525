import Immutable from 'immutable';

export const State = Immutable.fromJS({
	activities: {},
	medias: [],
	currentMedia: '東森新聞',
	currentNews: {},
	news: {},
	category: '媒體報導'
});
