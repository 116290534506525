import React, { Component } from 'react';


var ReactDOMServer = require('react-dom/server');
// var HtmlToReactParser = require('html-to-react').Parser;

class TestPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      html: '',
      responseData: {}
    };
  }
  componentDidMount() {
    fetch(`http://192.168.42.216:8000/payment/test`, {
     method: 'GET',
     headers: {
       'Content-Type': 'application/json'
     }
    })
    .then((response) => {
      if (!response.ok) throw new Error(response.statusText)
      return response.json();
     })
    .then((responseData) => {
      console.log(responseData)
      this.setState({ responseData })
      // console.log(responseData.html)

      //  document.open();
      // document.write(responseData.html);
      // document.close();

// var htmlToReactParser = new HtmlToReactParser();
// var htmlInput = responseData.html
// console.log(htmlInput)
// htmlInput = htmlInput.replace(/(\r\n|\n|\r)/gm,"");
// // console.log(htmlInput)
// var html = htmlToReactParser.parse(htmlInput);      
// console.log(html)
// var reactHtml = ReactDOMServer.renderToStaticMarkup(html);
 
// console.log(reactHtml);
      // this.steState({ html })
     })    
    .catch((error) => {
      console.log(error)
    })
  }      
  render() {
  	return (
	    <div >
        <form action="https://payment-stage.ecpay.com.tw/Cashier/AioCheckOut/V5" method="POST">
          {
            Object.keys(this.state.responseData).map(key => (
              <input key={key} type="text" name={key} value={this.state.responseData[key]}/>
            ))
          }
          <button type="submit">SUBMIT</button>
        </form>
	    </div>
	  )
  }
}

export default TestPageComponent;