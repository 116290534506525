import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  'stores/UPDATE_SELECTED_STORE_ID': (state, { payload }) => {
    return state.setIn(['selectedStoreId'], payload.storeId);
  },
  'stores/UPDATE_STORES': (state, { payload }) => {
    return state.setIn(['stores'], fromJS(payload.stores));
  },
  'stores/UPDATE_STORE_ID_LIST': (state, { payload }) => {
    return state.setIn(['storeIdList'], fromJS(payload.storeIdList));
  }    
}, State);

export default Reducer;