import { handleActions } from 'redux-actions';
import { State } from './model';


const Reducer = handleActions({
  'shoppingCompleted/UPDATE_ORDER_ID': (state, { payload }) => {
    return state.setIn(['orderId'], payload.orderId);      
  },
  'shoppingCompleted/UPDATE_IS_COMPLETED': (state, { payload }) => {
    return state.setIn(['isCompleted'], payload.isCompleted);      
  },    
  'shoppingCompleted/UPDATE_IS_LOGGED_IN': (state, { payload }) => {
    return state.setIn(['isLoggedIn'], payload.isLoggedIn);      
  },    
}, State);

export default Reducer;