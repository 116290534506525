import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  'news/UPDATE_CATEGORY': (state, { payload }) => {
    return state.setIn(['category'], payload.category);
  },  
  'news/UPDATE_ACTIVITIES': (state, { payload }) => {
    return state.setIn(['activities'], fromJS(payload.activities));
  },
  'news/UPDATE_NEWS': (state, { payload }) => {
    return state.setIn(['news'], fromJS(payload.news));
  },  
  'news/UPDATE_MEDIAS': (state, { payload }) => {
    return state.setIn(['medias'], fromJS(payload.medias));
  },   
  'news/UPDATE_CURRENT_MEDIA': (state, { payload }) => {
    return state.setIn(['currentMedia'], payload.currentMedia);
  },
  'news/UPDATE_CURRENT_NEWS': (state, { payload }) => {
    return state.setIn(['currentNews'], payload.currentNews);
  },      
}, State);

export default Reducer;