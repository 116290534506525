import Immutable from 'immutable';

export const State = Immutable.fromJS({
  id: 0,
  paymentDate: '',
  paymentMethod: '',
  paymentStatus: '',
  status: '',
  totalAmount: '',
  shippingCost: '',
  balanceDue: '',
  packageList: [],
  redeemList: []
});
