import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import * as api from '../../utils/api';
import * as cookie from '../../utils/cookiesTool';


export function doClickLoginFb(history) {
	return dispatch => {
    return api.rFbAuthUrl().then(result => {
      cookie.setCookie('loginMethod', 'FB');
      cookie.setCookie('stormedToken', result.stormedToken);
      window.location.href = result.url;
    }).catch((errMsg) => {
      console.log(errMsg);
    })
	}
}