import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  'redeem/UPDATE_PRODUCT': (state, { payload }) => {
    return state.setIn(['product'], fromJS(payload));
  },	
  'redeem/UPDATE_BUY_QUANTITY': (state, { payload }) => {
    return state.setIn(['buyQuantity'], payload.buyQuantity);
  },
  // 'stores/UPDATE_STORE_ID_LIST': (state, { payload }) => {
  //   return state.setIn(['storeIdList'], fromJS(payload.storeIdList));
  // }    
}, State);

export default Reducer;