export const UPDATE_IS_LOGGED_IN = 'shoppingProgress/UPDATE_IS_LOGGED_IN'
export const UPDATE_IS_COMPLETED = 'shoppingProgress/UPDATE_IS_COMPLETED'

export const UPDATE_BUY_PRODUCTS = 'shoppingProgress/UPDATE_BUY_PRODUCTS'
export const UPDATE_BUY_REDEEMS = 'shoppingProgress/UPDATE_BUY_REDEEMS'
export const UPDATE_PRODUCTS = 'shoppingProgress/UPDATE_PRODUCTS'
export const UDPATE_SHIPPING_LIMIT = 'shoppingProgress/UDPATE_SHIPPING_LIMIT'
export const UPDATE_STORE_ID_NAMES = 'shoppingProgress/UPDATE_STORE_ID_NAMES'
export const UPDATE_SHOPPING_DATA = 'shoppingProgress/UPDATE_SHOPPING_DATA'

export const UPDATE_SHOPPING_COUNT = 'headerBar/UPDATE_SHOPPING_COUNT'


export const UPDATE_ORDER_ID = 'shoppingCompleted/UPDATE_ORDER_ID'

// specials
export const CLEAN_BUY_SPECIALS = 'specials/UPDATE_BUY_SPECIALS'
export const UPDATE_REMOVE_SPECIAL = 'specials/UPDATE_REMOVE_SPECIAL'

export const UPDATE_MEMBER_REDEEM = 'login/UPDATE_MEMBER_REDEEM'
