import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import FeaturedComponent from './component';


const mapStateToProps = (state) => ({
  someFeaturedList: selectors.getSomeFeaturedList(state)
});

const mapDispatchToProps = dispatch => ({
  onLoadFeatuerdMenu: (type) => {
    dispatch(actions.onLoadFeatuerdMenu(type));
  },
  onClickToLeft: () => {
    dispatch(actions.doClickToLeft());
  },
  onClickToRight: () => {
    dispatch(actions.doClickToRight());
  }    
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeaturedComponent);