import Immutable from 'immutable';

export const State = Immutable.fromJS({
	productCategoryIdList: [],
	productCategories: {},
	productIdList: [], 
	products: {},
	selectedProductId: '0',
	selectedProductCategoryId: '0'
});
