import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
import stores from '../modules/stores';
import store from '../modules/store';
import googleMap from '../modules/googleMap';
// images
import storesBanner from '../images/storesBanner.png'


class StoresPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
  	return (
	    <div>
        <Helmet>
          <title>鄧師傅功夫菜-門市資訊</title>
          <meta name="description" content="飄香高雄35載，滷豬腳年銷售超越50座臺北101大樓高度! 馳名海內外，獲得媒體一致推薦。Chef Teng founded in 1984, Taiwan, is famous for signature stewed pork knuckles and has won awards and recognition from news media around the world." />
        </Helmet>
        <headerBar.container/>

        {/* <div className="BannerTitle StoresBannerTitle">
          <p className="center">門市資訊</p>
        </div>  */}
        <div className="row TitleBox">
          <p className="col-auto mr-auto Title">門市資訊</p>
          <p className="col-auto DirectionTitle">首頁 / 門市資訊</p>
        </div>

        <div className="container align-items-center StoresMap">
          <div div className="row justify-content-center align-items-center">
            <div className="col-lg-7 MapBox">
              {
                this.props.coordinate
                &&
                <googleMap.component
                  longitude={parseFloat(this.props.coordinate.split(",")[0])}
                  latitude={parseFloat(this.props.coordinate.split(",")[1])}
                />          
              }
            </div>         
            <div className="col-lg-5">
              <store.container
                storeData={this.props.storeData}
              />
            </div>
          </div>
        </div>  
        
        <stores.container/>
        <footer.container/>
	    </div>
	  )
  }
}

const mapStateToProps = (state) => ({
  coordinate: stores.selectors.getSelectedStoreCoordinate(state),
  storeData: stores.selectors.getSelectedStore(state)
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoresPageComponent);