import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  'products/UPDATE_SELECTED_PRODUCT_CATEGORY_ID': (state, { payload }) => {
    return state.setIn(['selectedProductCategoryId'], payload.productCategoryId);
  },	
  'products/UPDATE_SELECTED_PRODUCT_ID': (state, { payload }) => {
    return state.setIn(['selectedProductId'], payload.productId);
  },
  'products/UPDATE_PRODUCTS': (state, { payload }) => {
    return state.setIn(['products'], fromJS(payload.products));
  },
  'products/UPDATE_PRODUCT_ID_LIST': (state, { payload }) => {
    return state.setIn(['productIdList'], fromJS(payload.productIdList));
  },
  'products/UPDATE_PRODUCT_CATEGORIES': (state, { payload }) => {
    return state.setIn(['productCategories'], fromJS(payload.productCategories));
  },
  'products/UPDATE_PRODUCT_CATEGORY_ID_LIST': (state, { payload }) => {
    return state.setIn(['productCategoryIdList'], fromJS(payload.productCategoryIdList));
  } 
}, State);

export default Reducer;