import { handleActions } from 'redux-actions';
// import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  'login/EDIT_LOGIN': (state, { payload }) => {
    switch(payload.type) {
      case 'account':
        return state.setIn(['account'], payload.value);
      case 'password':
        return state.setIn(['password'], payload.value);
      default:
        return state;
    }    
  },
  'login/UPDATE_ERROR_MESSAGE': (state, { payload }) => {
    return state.setIn(['errMessage'], payload.message);
  },
  'login/UPDATE_MEMBER_ID': (state, { payload }) => {
    return state.setIn(['memberId'], payload.memberId);
  },
  'login/UPDATE_SHOW_LOADING': (state, { payload }) => {
    return state.setIn(['showLoading'], payload.showLoading);
  },
  'login/UPDATE_MEMBER_REDEEM': (state, { payload }) => {
    return state.setIn(['memberRedeem'], payload);
  },
}, State);

export default Reducer;