import { handleActions } from 'redux-actions';
// import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  'headerBar/SHOW_USER_DROP_DOWN': (state, { payload }) => {
    return state.setIn(['showUserDropDown'], payload.show);
  },
  'headerBar/UPDATE_SHOPPING_COUNT': (state, { payload }) => {
  	if (payload.count !== 0) {
  		const shoppingCount = state.getIn(['shoppingCount']);
    	return state.setIn(['shoppingCount'], shoppingCount + payload.count);
  	} else {
  		return state.setIn(['shoppingCount'], 0);
  	}
    
  }
}, State);

export default Reducer;