import { createAction } from 'redux-actions';
import { message } from 'antd';
import * as types from './actionTypes';
import * as api from '../../utils/api';
import * as cookie from '../../utils/cookiesTool';
import * as validation from '../../utils/validation';


const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});

export const doClickProductCategory = createAction(types.UPDATE_SELECTED_PRODUCT_CATEGORY_ID)

export const doUpdateProducts = createAction(types.UPDATE_PRODUCTS)
export const doUpdateProductIdList = createAction(types.UPDATE_PRODUCT_ID_LIST)
export const doUpdateProductCategories = createAction(types.UPDATE_PRODUCT_CATEGORIES)
export const doUpdateProductCategoryIdList = createAction(types.UPDATE_PRODUCT_CATEGORY_ID_LIST)
export function doLoadProductsPage() {  
  return dispatch => {
    api.rProductCategories().then(result => {  
      const productCategoryIdList = [];
      const productCategories = {};
      result.forEach(category => {
        productCategoryIdList.push(`${category.categoryId}`);
        productCategories[category.categoryId] = category;
      })
      dispatch(doUpdateProductCategoryIdList({ productCategoryIdList }));
      dispatch(doUpdateProductCategories({ productCategories }));

      api.rPackages().then(result => {  
        // console.log(result)
        const productIdList = [];
        const products = {};
        result.forEach(n => {
          productIdList.push(`${n.id}`);
          products[n.id] = n;
        })
        dispatch(doUpdateProductIdList({ productIdList }));
        dispatch(doUpdateProducts({ products }));
      }).catch((errMsg) => {
        console.log(errMsg)
      })          
    }).catch((errMsg) => {
      console.log(errMsg)
    })    
  }
}

export const doUpdateBuyProducts = createAction(types.UPDATE_BUY_PRODUCTS)
export const doUpdateShoppingCount = createAction(types.UPDATE_SHOPPING_COUNT)
export function doClickProductShoppingCart(productId) {
  return (dispatch, getState) => {
    const state = getState();
    const products = state.getIn(['products', 'products']);
    const buyProducts = state.getIn(['shoppingProgress', 'buyProducts']);
    const buyRedeems = state.getIn(['shoppingProgress', 'buyRedeems']);
    const buySpecials = state.getIn(['specials', 'buySpecials']);

    let existShipping = validation.getExistShipping(products, buyProducts, buyRedeems, buySpecials)
    let canShippingSelf = existShipping.canShippingSelf;
    let canShippingDelivery = existShipping.canShippingDelivery;
    let shippingSelfDateList = existShipping.shippingSelfDateList;
    let shippingDeliveryDateList = existShipping.shippingDeliveryDateList;

    const addProduct = products.getIn([productId]);
    if (addProduct.getIn(['shippingSelf']) === false) {
      canShippingSelf = false;
    }
    if (addProduct.getIn(['shippingDelivery']) === false) {
      canShippingDelivery = false;
    }
    // 一部份商品只允許自取、另有一部份商品只允許宅配
    if (canShippingSelf === false && canShippingDelivery === false) {
      message.error('購物車已包含其它限期商品，並與此商品的取貨方式衝突，請分開訂購');
      return;
    }
    // 已存在限定自取日期的商品，新加入的限期商品至少要有一天重疊
    if (canShippingSelf === true && shippingSelfDateList.length > 0 && addProduct.getIn(['shippingSelfDateList']).size > 0) {
      if (!shippingSelfDateList.some(shippingDate => addProduct.getIn(['shippingSelfDateList']).indexOf(shippingDate) !== -1)) {
        message.error('購物車已包含其它限期商品，並與此商品的取貨日期衝突，請分開訂購');
        return;
      }
    }
    // 已存在限定宅配日期的商品，新加入的限期商品至少要有一天重疊
    if (canShippingDelivery === true && shippingDeliveryDateList.length > 0 && addProduct.getIn(['shippingDeliveryDateList']).size > 0) {
      if (!shippingDeliveryDateList.some(shippingDate => addProduct.getIn(['shippingDeliveryDateList']).indexOf(shippingDate) !== -1)) {
        message.error('購物車已包含其它限期商品，並與此商品的取貨日期衝突，請分開訂購');
        return;
      }
    }

    const qty = addProduct.getIn(['quantity']);
    let nowBuyQty = buyProducts.getIn([productId]);
    if (!nowBuyQty) {
      nowBuyQty = 0;
    }
    if (nowBuyQty < qty) {
      dispatch(doUpdateBuyProducts({ productId, buyQuantity: 1 }));
      dispatch(doUpdateShoppingCount({ count: 1 }));
      message.info('已加入購物車');
    } else {
      message.info('該商品可購買數量已滿');
    }
  }
}