import React, { Component } from 'react';
import locationIcon from '../../images/map_pin.svg';


/*
input:
1. storeData: the data of the store
*/
class StoreComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { 
      storeData
    } = this.props;
  	return (
	    <div>
        {
          storeData
          &&
          <div className="StoreContent">
            <p className="Name">{storeData.getIn(['storeName'])}</p>
            <div className="Content">  
              <p>{storeData.getIn(['storeHour'])}</p>
              <a href={`tel:${storeData.getIn(['storePhone'])}`}>{storeData.getIn(['storePhone'])}</a>
              <p>{storeData.getIn(['storeAddress'])} {storeData.getIn(['description'])} &nbsp;&nbsp;<img src={locationIcon} alt=""/></p>
            </div>
          </div>
        }
	    </div>
	  )
  }
}

export default StoreComponent;