import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
import banner from '../images/page-memberInfo.jpg';

class MemberInfoPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div id="page-memberInfo">
        <Helmet>
          <title>鄧師傅功夫菜-會員須知</title>
          <meta name="description" content="飄香高雄35載，滷豬腳年銷售超越50座臺北101大樓高度! 馳名海內外，獲得媒體一致推薦。Chef Teng founded in 1984, Taiwan, is famous for signature stewed pork knuckles and has won awards and recognition from news media around the world." />
        </Helmet>
        <headerBar.container />

        <div className="row TitleBox">

        </div>

        <div className="content">
          <img src={banner} alt="鄧師傅會員須知" />
          <div>
            歡迎您使用鄧師傅功夫菜官方網站，本平台為鄧師傅餐飲事業所經營，並依據本平台使用條款及其他相關規範或公告提供您相關服務。您第一次購物前，請詳細閱讀並同意本平台使用條款後，再行填寫您的基本資料註冊會員以完成購物流程。為確保個人權益，註冊者應填寫本人正確資料，若有變動並請盡速更新，藉以維持系統紀錄資料之正確性。註冊者不得冒用別人的身分進行申請，否則需自負相關民刑事法律責任。鄧師傅將以本次申請單所記載或最近一次系統登錄之基本資料為記錄依據。鄧師傅將以系統紀錄之地址、行動電話或電子郵件、帳號等資料，不定期發送訊息、電子郵件，進行會員點數累兌點活動及優惠資訊，為確保您的權益，請務必提供並維持資料正確性。<br />
            <br />
            您完成前述購物流程，即表示您已經閱讀、瞭解並同意接受本平台使用條款之全部內容與約定，如不同意本平台使用條款之全部或部分者，請勿填寫基本資料及完成後續購物流程。
            您同意本公司有權因應營運之需求，隨時調整本平台使用條款，除對您已完成之購物權益有重大影響外，本公司將不會個別通知。本平台使用條款之最新內容，將公布於本平台會員須知中，您於每次進行購物前，均可詳細閱讀，以維護您的權益。您於任何修改或變更之後繼續使用本平台服務，將視為您已經閱讀、瞭解並同意接受相關修改及變更。如您不同意本平台使用條款之修改或變更，請停止使用本平台相關服務。若您尚未滿二十歲，請您在父、母或監護人的陪同下，詳閱本平台使用條款並同意後，才進行相關購物流程。您及法定代理人或監護人均同意後續在本平台之購物行為，屬於日常生活所必須，並擔保已取得法定代理人或監護人之同意。<br />
            <br />
          </div>
          <div>
            <div className="title">【帳號&密碼】</div>
            <ul>
              <li>
                一、本平台為保障交易安全，將以您申請所填寫的電子郵件信箱或手機號碼作為帳號，並由您自行選定一組密碼作為個人登入辨識使用，您可依需要自行變更個人密碼。請勿使用與其他平台相同之密碼，並經常變更您的個人密碼，以維護您帳號的安全。
              </li>
              <li>
                二、您同意就您的帳號與密碼善盡保管與保密之責任，包含但不限於：不洩漏帳戶、密碼予第三人、不與他人共用帳號、密碼、適時登出本平台等。除經證明係第三人違法使用之情形，您須為利用您所設定之正確帳號、密碼登入之使用行為負全部責任，不得任意否認交易。
              </li>
              <li>
                三、您聲明並保證您所填寫之基本資料為您正確且完整的資料，不得使用他人資料，如有違反前開保證，本公司有權暫停或終止您的帳號，並拒絕您使用本公司的服務。
              </li>
              <li>
                四、您的帳號若有被冒用、盜用之情形，應立即通知本公司，以避免損害擴大。本公司將協助暫停該帳號相關交易及後續利用，並於釐清帳號使用問題後，依您的請求重新設定密碼開通使用。
              </li>
            </ul>
          </div>
          <div>
            <div className="title">【個人資料保護】</div>
            <div>
              本公司於您每次交易或參加活動時，為提供本公司相關服務及行銷之目的，蒐集、處理及利用您的個人資料。有關個人資料相關應告知事項，請參閱鄧師傅功夫菜隱私權保護政策。<br />
              本公司將遵守個人資料保護相關法令的規定，除依本平台使用條款、隱私權政策或法律規定外，不會違法利用您的個人資料。在下列的情況下，本公司有可能會提供您的個人資料給相關機關，或主張其權利受侵害並提示司法機關正式文件之第三人：<br />
            </div>
            <ul>
              <li>一、依法令或受檢警調、司法機關或其他有權機關基於法定程序之要求；</li>
              <li>二、在緊急情況下為維護其他客戶或是第三人之合法權益；</li>
              <li>三、為維護本平台的正常運作；</li>
              <li>四、為提供本公司相關服務產生的金流、物流或其他協力廠商或合作廠商必要資訊；</li>
              <li>五、使用者有任何違反政府法令或本平台使用條款之情形。</li>
            </ul>
            <div>
              本平台可能因廣告或其他合作促銷活動而包含其他平台之連結，您點選該等連結至其他平台，即不適用本平台使用條款及隱私權政策之規範。您須自行判斷各該平台相關條款對您的權益保障是否足夠，再決定是否使用該平台服務。<br />
            </div>
          </div>
          <div>
            <div className="title">【智慧財產權】</div>
            <div>
              本平台所有內容，包括但不限於：商品攝影、模特兒照片、美術插圖、平台架構、程式設計、平台畫面的安排、設計版式、商標、其他標示或資訊等，均屬本公司或相關權利人依法擁有智慧財產權之客體，受著作權法、商標法、專利法、營業秘密法及公平交易法之保護，非經本公司或權利人事先許可或授權，不得任意使用，以免涉侵權或違法之責任。<br />
            </div>
          </div>
          <div>
            <div className="title">【違約處理】</div>
            <div>
              為維護本平台全體使用者之權益，若您有下列情形之一者，本公司得暫停或終止您的購物資格或拒絕您使用本平台全部或部份服務：
            </div>
            <ul>
              <li>一、提供錯誤或不實資料進行登錄；</li>
              <li>二、未經本人許可而有盜刷信用卡或其他盜用金融帳號情形；</li>
              <li>
                三、已出貨包裹因個人因素而退件，或超過本公司所規範未取次數之情形
                <ul>
                  <li>(1) 選用門市取貨付款，未於通知期限內至指定門市取件</li>
                  <li>(2) 選用宅配貨到付款，物流公司配送時，無人簽收或拒收包裹</li>
                </ul>
              </li>
              <li>四、經本公司認定因個人因素進行經常性退貨等行為；</li>
              <li>五、對本公司客服部門為騷擾或不當言行；</li>
              <li>六、其他違反本平台使用條款或違反法律規定、未遵循雙方約定、惡意濫用服務權益之客戶。</li>
            </ul>
            <div>
              您同意本公司依本平台使用條款暫停或終止您的購物資格，或拒絕您使用本平台全部或部份服務時，均無須得到您的同意，且本公司對您或任何第三人均不負任何義務或責任。<br />
            </div>
          </div>
          <div>
            <div className="title">【本同意書效力、準據法與管轄法院】</div>
            本平台使用條款任何條款的全部或一部份無效時，並不影響其他約定的效力。您與本公司的權利義務關係，應依本平台使用條款及相關適用的本平台公告或規範定之。若有發生任何爭議，您可以向本平台上所記載的客服聯絡方式提出申訴或反應，雙方均應秉持最大誠意，依誠實信用、平等互惠原則協商解決之。若仍未能協商解決，除法律另有規定者外，雙方同意以台灣高雄地方法院為第一審管轄法院。<br />
          </div>
          <br />
          <div>本同意書若有未盡之處，依中華民國法律解釋、補充及辦理。</div>
        </div>

        <footer.container />
      </div>
    )
  }
}

export default MemberInfoPageComponent;
