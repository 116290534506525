import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import AboutUsComponent from './component';


const mapStateToProps = (state) => ({
  about: selectors.getAbout(state)
});

const mapDispatchToProps = dispatch => ({
  onLoadAboutUs: () => {
    dispatch(actions.doLoadAboutUs());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutUsComponent);