import { handleActions } from 'redux-actions';
// import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  'registration/POST_REQUEST': (state, { payload }) => {
    return state.setIn(['isPosting'], true)
                .setIn(['errMsg'], null);
  },
  'registration/POST_SUCCESS': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], null);
  },
  'registration/POST_FAILURE': (state, { payload }) => {
    return state.setIn(['isPosting'], false)
                .setIn(['errMsg'], payload.errMsg);
  },
  'registration/EDIT_REGISTRATION': (state, { payload }) => {
    switch(payload.type) {
      case 'name':
        return state.setIn(['name'], payload.value);
      case 'gender':
        return state.setIn(['gender'], payload.value);
      case 'email':
        return state.setIn(['email'], payload.value);
      case 'phone':
        return state.setIn(['phone'], payload.value);
      case 'city':
        return state.setIn(['city'], payload.value);
      case 'address':
        return state.setIn(['address'], payload.value);
      case 'password':
        return state.setIn(['password'], payload.value);
      case 'confirmPassword':
        return state.setIn(['confirmPassword'], payload.value);
      case 'TaiwanCity':
        return state.setIn(['TaiwanCity'], payload.value);
      default:
        return state;
    }
  },
}, State);

export default Reducer;