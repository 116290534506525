import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
import banner from '../images/page-redeemInfo.jpg';
import redeemInfo01 from '../images/redeemInfo22040601.jpg';

class RedeemInfoPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div id="page-redeemInfo">
        <Helmet>
          <title>鄧師傅功夫菜-集點須知</title>
          <meta name="description" content="飄香高雄35載，滷豬腳年銷售超越50座臺北101大樓高度! 馳名海內外，獲得媒體一致推薦。Chef Teng founded in 1984, Taiwan, is famous for signature stewed pork knuckles and has won awards and recognition from news media around the world." />
        </Helmet>
        <headerBar.container />

        <div className="row TitleBox">
          <p className="col-auto m-auto Title Tab">
            <span>
              <Link className="title" to={`/redeems`}>
                兌換品項
              </Link>
            </span>
            <span className="titleActive">
              集點須知
            </span>
            <span>
              <Link className="title" to={`/memberNotice`}>
                會員新制公告
              </Link>
            </span>
            <span>
              <Link className="title" to={`/memberQA`}>
                會員 Q&A
              </Link>
            </span>
            <span>
              <Link className="title" to={`/loveLighting`}>
                用愛點亮餐桌
              </Link>
            </span>
          </p>
        </div>

        <div className="content">
          <img src={banner} alt="鄧師傅集點須知" />
          <div className="title">【會員集點須知】</div>
          <ul>
            <li>
              一、會員優惠權益
              <ul className="sub">
                <li>1. 首次加入「鄧師傅功夫菜門市會員」者，即贈30點會員點數。</li>
                <li>2. 消費滿額贈會員點數：單筆消費滿30元，即獲得1點，消費愈多點數愈多。點數可兌換鄧師傅會員集點之商品，無法直接兌換現金，亦無法轉移給其他會員使用。</li>
                <li>3. 會員將獲得最新消息，以及不定期的優惠通知。</li>
              </ul>
            </li>
            <li>
              二、會員點數使用規定：
              <ul className="sub">
                <li>1. 門市會員，須於鄧師傅功夫菜官方 LINE@ 中的會員專區，註冊以利開通門市集點功能。</li>
                <img src={redeemInfo01} alt="會員集點須知" />
                <li>2. 官網會員，須於鄧師傅功夫菜官方網站註冊以利開通官網購物集點功能。</li>
                <li>3. 門市會員以及官網會員皆須註冊開通會員集點功能，系統方能以會員註冊的手機號碼，進行門市會員以及官網會員的串聯，合併門市及官網消費所累積的點數。</li>
                <li>4. 點數累計以實際付款金額為點數發放計算基礎，凡已使用現金折價券或運費、折扣代碼等優惠之金額及點數兌換，不再另行計算。</li>
                <li>5. 會員單次單筆交易達累點門檻，可出示會員條碼或報手機號碼進行集點，如消費者放棄點數累點，本公司將不予事後補點，也不得有其他要求。</li>
                <li>6. 點數可兌換之贈品內容將不定期更新，會員不得要求兌換現金，或兌換非贈品兌換期限內之贈品。</li>
                <li>7. 本公司有權利使用個人資料以提供鄧師傅集點累點服務。</li>
                <li>8. 集點之點數，會員不得轉贈第三方並不得要求以任何形式轉換。</li>
              </ul>
            </li>
            <li>
              三、會員點數使用期限
              <ul className="sub">
                <li>1. 點數領取後 365 天內須使用完畢，未使用的點數，系統將自動收回。</li>
                <li>2. 以上期間內再次消費集點或兌換，則可延長點數使用期限365天。</li>
              </ul>
            </li>
            <li>
              四、退貨時點數處理<br />
              會員於店鋪交易後退貨，需持交易明細、發票或載具及原商品至原店鋪退貨，本公司將扣回該筆發票金額所贈送之點數。會員若以點數加價購的方式購買商品之退貨時，本公司僅退還發票金額貨款，不再退還點數。
            </li>
            <li>
              五、會員門號掛失或移轉處理辦法<br />
              會員註冊之手機掛失或新辦門號等，可重新註冊後，提出相關證明申請會員點數移轉。
            </li>
            <li>
              六、服務中止及免責聲明
              <ul className="sub">
                <li>
                  1. 鄧師傅餐飲事業保有對會員集點樹集點之服務說明內容及行銷活動之解釋、修改、調整等相關權利。鄧師傅會員同意鄧師傅餐飲事業對此會員集點之約定條款、個人資料保護告知暨同意事項、使用規則等，得隨時予以修改或變更，並於修改或變更前之一定期間，於鄧師傅餐飲事業門市及網站公告生效。鄧師傅會員同意，當鄧師傅會員系統終止服務時，鄧師傅餐飲事業對您或任何第三人均不承擔責任。
                </li>
                <li>
                  2. 於發生下列情形之一時，鄧師傅餐飲事業有權暫時停止或中斷會員服務：
                  <ul className="sub">
                    <li>（a）鄧師傅餐飲事業網站電子通信設備進行必要之保養及施工時。</li>
                    <li>（b）鄧師傅餐飲事業會員系統進行必要之保養及施工時。</li>
                    <li>（c）發生突發性之電子通信設備故障時。</li>
                    <li>（d）鄧師傅餐飲事業網站或本卡系統，申請之電子通信服務被停止，無法提供服務時。</li>
                    <li>（e）由於天災等不可抗力之因素或其他不可歸責於鄧師傅餐飲事業之原因致無法提供服務時。</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <footer.container />
      </div>
    )
  }
}

export default RedeemInfoPageComponent;
