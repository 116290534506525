export function setCookie(cookieName, cookieValue, exdays=1) {
  if (document.cookie.indexOf(cookieName) >= 0) {
    let expiresDate = new Date();
    expiresDate.setTime(expiresDate.getTime() + (-1 * 24 * 60 * 60 * 1000));
    const updateExpires = "expires=" + expiresDate.toUTCString();
    document.cookie = cookieName + "=" + cookieValue + "; " + updateExpires + ";path=/";
  }
  let date = new Date();
  date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = cookieName + "=" + cookieValue + "; " + expires + ";path=/";
}

export function getCookie(cookieName) {
  const name = cookieName + "=";
  const allCookie = document.cookie.split(";");
  for (let i = 0; i < allCookie.length; i++) {
    let cookieData = allCookie[i];
    while (cookieData.charAt(0) === " ") cookieData = cookieData.substring(1);
    if (cookieData.indexOf(name) === 0) {
      return cookieData.substring(name.length, cookieData.length);
    }
  }
  return "";
}

export function deleteCookie(cookieName) {
  document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function deleteAllCookies() {
  document.cookie.split(";").forEach(c => {
    document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/") 
  })
}

