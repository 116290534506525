import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import ProductsComponent from './component';
import * as loginSelectors from '../login/selectors';
import * as loginActions from '../login/actions';

const mapStateToProps = (state) => ({
  productIdList: selectors.getProductIdList(state),
  products: selectors.getProducts(state),
  productCategoryIdList: selectors.getProductCategoryIdList(state),
  productCategories: selectors.getProductCategories(state),
  selectedProductId: selectors.getSelectedProductId(state),
  selectedProductCategoryId: selectors.getSelectedProductCategoryId(state),
  orderId: selectors.getOrderId(state),
  purchaserEmail: selectors.getPurchaserEmail(state),
  memberId: loginSelectors.getMemberId(state),
});

const mapDispatchToProps = dispatch => ({
  onClickProductShoppingCart: (productId) => {
    dispatch(actions.doClickProductShoppingCart(productId));
  },
  onClickProductCategory: (productCategoryId) => {
    dispatch(actions.doClickProductCategory({ productCategoryId }));
  },
  onLoadProductsPage: () => {
    dispatch(actions.doLoadProductsPage());
  },
  onLoadMemberRedeem: (memberId) => {
    dispatch(loginActions.doLoadMemberRedeem(memberId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsComponent);