import { createSelector } from 'reselect';

export const getSelectedProductId = state => state.getIn(['redeems', 'selectedProductId']);
export const getSelectedProductCategoryId = state => state.getIn(['redeems', 'selectedProductCategoryId']);

export const getAllProductIdList = state => state.getIn(['redeems', 'productIdList']);
export const getProducts = state => state.getIn(['redeems', 'products']);
export const getProductIdList = createSelector(
  [getSelectedProductCategoryId, getAllProductIdList, getProducts],
  (selectedProductCategoryId, allProductIdList, products) => {
  	return allProductIdList.filter(productId => {
			if (selectedProductCategoryId === '0') {
				return true;
			}
			if (selectedProductCategoryId === '1') {
				if (products.getIn([productId, 'popular']) === 1) {
					return true;
				}
			}	else if (products.getIn([productId, 'categoryId']) === parseInt(selectedProductCategoryId)) {
				return true;
			}
			return false;
		})
	}
)

export const getProductCategoryIdList = state => state.getIn(['redeems', 'productCategoryIdList']);
export const getProductCategories = state => state.getIn(['redeems', 'productCategories']);

export const getOrderId = state => state.getIn(['shoppingCompleted', 'orderId']);
export const getPurchaserEmail = state => state.getIn(['shoppingProgress', 'purchaserEmail']);