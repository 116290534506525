import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import HeaderBarComponent from './component';
import * as shoppingProgressSelectors from '../shoppingProgress/selectors';

const mapStateToProps = (state) => ({
  name: selectors.getName(state),
  showUserDropDown: selectors.getShowUserDropDown(state),
  shoppingCount: selectors.getShoppingCount(state),
  memberRedeem: selectors.getMemberRedeem(state),
  buyProducts: shoppingProgressSelectors.getBuyProducts(state),
  buyRedeems: shoppingProgressSelectors.getBuyRedeems(state),
});

const mapDispatchToProps = dispatch => ({
  onClickUser: (history) => {
    dispatch(actions.doClickUser(history));
  },
  onClickLogout: () => {
    dispatch(actions.doLogout());
  },  
  onLeaveUser: () => {
    dispatch(actions.doShowUserDorpDown({ showUserDropDown: false }));
  },    
  // onEditLogin: (value, type) => {
  //   dispatch(actions.doEditLogin({ value, type }));
  // }
  onShoppingCarTimeout: () => {
    dispatch(actions.doShoppingCarTimeout());
  },
  onClickShoppingCart: () => {
    dispatch(actions.updateShoppingData({ value: 0, type: 'currentStep' }));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderBarComponent);