import { handleActions } from 'redux-actions';
// import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  'resetPasswordWithToken/EDIT_RESET_PASSWORD': (state, { payload }) => {
    switch(payload.type) {
      case 'password':
        return state.setIn(['password'], payload.value);
      case 'confirmPassword':
        return state.setIn(['confirmPassword'], payload.value);
      default:
        return state;
    }    
  },
  'resetPasswordWithToken/UPDATE_ERROR_MESSAGE': (state, { payload }) => {
    return state.setIn(['errMessage'], payload.message);
  },
  'resetPasswordWithToken/UPDATE_IS_RESET': (state, { payload }) => {
    return state.setIn(['isReset'], payload.isReset);
  }      
}, State);

export default Reducer;