import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';


class RegistrationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.onLoadPurchaserEmail();
  }
  render() {
    const { 
      name, gender, email, phone, city, address, password, confirmPassword, errMsg, 
      onEditRegistration, onSubmitRegistration, TaiwanCity,
    } = this.props;

  	return (
	    <div>

        <form onSubmit={(event) => {event.preventDefault();onSubmitRegistration(this.props.history)}}>

          <table className="table table-rwd">

            <tbody>
              <tr>
                <td className="TD1"><p className="RegistrationText">姓名/稱謂 (Name/Title)</p></td>
                <td className="TD2"><div className="TableGoldLine"></div></td>
                <td className="TD3">
                  <input className="RegistrationInput" type="text"
                    value={name}
                    onChange={(event) => onEditRegistration(event.target.value, "name")}
                    required
                  />
                  <div className="RadioText">
                  <label className="normalFont"><input className="RegistrationInput" type="radio" name="gender" value="male"
                      checked={gender === "male"}
                      onChange={(event) => onEditRegistration(event.target.value, "gender")}
                    />&nbsp;&nbsp;先生 &nbsp;&nbsp;&nbsp;&nbsp;</label>
                    <label className="normalFont"><input className="RegistrationInput" type="radio" name="gender" value="female"
                      checked={gender === "female"}
                      onChange={(event) => onEditRegistration(event.target.value, "gender")}
                    /> &nbsp;小姐</label>
                  </div>
                </td>
              </tr>

              <tr>
                <td><p className="RegistrationText">電子郵件 (E-mail)</p></td>
                <td><div className="TableGoldLine"></div></td>
                <td>
                  <input className="RegistrationInput" type="email"
                    value={email}
                    onChange={(event) => onEditRegistration(event.target.value, "email")}
                    required
                  />
                  <span className="RegistrationTextRemarks">
                    註1：請填寫正確電子郵件信箱，以利收到訂單確認信函 <br/>
                    註2：因免費信箱及yahoo郵件容易將信件歸類為垃圾郵件，為取得即時訂單訊息請避免使用，謝謝
                  </span>
                </td>
              </tr>

              <tr>
                <td><p className="RegistrationText">行動電話 (Mobile)</p></td>
                <td><div className="TableGoldLine"></div></td>
                <td>
                  <input className="RegistrationInput" type="Tel" pattern="[0-9]{10}"
                    value={phone}
                    onChange={(event) => onEditRegistration(event.target.value, "phone")}
                    required
                  />
                </td>
              </tr>

              <tr>
                <td><p className="RegistrationText">收貨地址 (Address)</p></td>
                <td><div className="TableGoldLine"></div></td>
                <td>
                  <select id="categorySelectShopping" value={city} onChange={(event) => onEditRegistration(event.target.value, "city")}>
                    {
                      TaiwanCity.map(t  => (
                        <option key={t.getIn(["id"])} value={t.getIn(["city"])}>{t.getIn(["city"])}</option>
                      ))
                    }
                  </select>
                  <input className="RegistrationInput" type="text"
                    value={address}
                    onChange={(event) => onEditRegistration(event.target.value, "address")}
                    required
                  />
                </td>
              </tr>

              <tr>
                <td><p className="RegistrationText">密碼 (Password)</p><p>密碼需4位數以上</p></td>
                <td><div className="TableGoldLine"></div></td>
                <td>
                  <input className="RegistrationInput" type="password"
                    value={password}
                    onChange={(event) => onEditRegistration(event.target.value, "password")}
                    required
                  />
                </td>
              </tr>

              <tr>
                <td><p className="RegistrationText">確認密碼 (Confirm Password)</p></td>
                <td><div className="TableGoldLine"></div></td>
                <td>
                  <input className="RegistrationInput" type="password"
                    value={confirmPassword}
                    onChange={(event) => onEditRegistration(event.target.value, "confirmPassword")}
                    required
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <p className="ErrMessage">{errMsg}</p>
          
          <div className="container">
            <div className="row">
              <div className="RegistrationBtnBox col-md-3"></div>
              <div className="RegistrationBtnBox col-md-3">
                <Link to={`/login`}>
                  <button className="ReturnBtn_small" type="button">返回</button>
                </Link>  
              </div>
              <div className="RegistrationBtnBox col-md-3">
                <button className="SendBtn_small" type="submit" disabled={this.props.isPosting}>送出</button>
              </div>
              <div className="RegistrationBtnBox col-md-3"></div>
            </div>
          </div>
       
        </form>          
	    </div>
	  )
  }
}

export default withRouter(RegistrationComponent);
