import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import ProfileComponent from './component';


const mapStateToProps = (state) => ({
  name: selectors.getName(state),
  gender: selectors.getGender(state),
  email: selectors.getEmail(state),
  phone: selectors.getPhone(state),
  city: selectors.getCity(state),
  address: selectors.getAddress(state),
  TaiwanCity: selectors.getTaiwanCity(state)
});

const mapDispatchToProps = dispatch => ({
  onIsLoggedIn: (history) => {
    dispatch(actions.doIsLoggedIn(history));
  },    
  onLoadProfile: () => {
    dispatch(actions.doLoadProfile());
  },
  onLoadTaiwanCity: () => {
    dispatch(actions.doLoadTaiwanCity());
  },
  onChangeName: (name) => {
    dispatch(actions.doUpdateName({ name }));
  },
  onChangeGender: (gender) => {
    dispatch(actions.doUpdateGender({ gender }));
  },
  onChangeEmail: (email) => {
    dispatch(actions.doUpdateEmail({ email }));
  },
  onChangeCity: (city) => {
    dispatch(actions.doUpdateCity({ city }));
  }, 
  onChangeAddress: (address) => {
    dispatch(actions.doUpdateAddress({ address }));
  }, 
  onSubmitProfile: () => {
    dispatch(actions.doSubmitProfile());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileComponent);