import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
import banner from '../images/page-memberNotice.jpg';

class MemberNoticePageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div id="page-memberNotice">
        <Helmet>
          <title>鄧師傅功夫菜-會員新制公告</title>
          <meta name="description" content="飄香高雄35載，滷豬腳年銷售超越50座臺北101大樓高度! 馳名海內外，獲得媒體一致推薦。Chef Teng founded in 1984, Taiwan, is famous for signature stewed pork knuckles and has won awards and recognition from news media around the world." />
        </Helmet>
        <headerBar.container />
        <div className="row TitleBox">
          <p className="col-auto m-auto Title Tab">
            <span>
              <Link className="title" to={`/redeems`}>
                兌換品項
              </Link>
            </span>
            <span>
              <Link className="title" to={`/redeemInfo`}>
                集點須知
              </Link>
            </span>
            <span className="titleActive">
              會員新制公告
            </span>
            <span>
              <Link className="title" to={`/memberQA`}>
                會員 Q&A
              </Link>
            </span>
            <span>
              <Link className="title" to={`/loveLighting`}>
                用愛點亮餐桌
              </Link>
            </span>
          </p>
        </div>

        <div className="content">
          <img src={banner} alt="鄧師傅會員新制公告" />
          <h3>2022/01/28 即日起，會員集點服務全面升級。</h3>
          <div className="title">會員帳號升級，一支手機號碼走跳線上線下！</div>
          <div>門市會員以及官網會員皆須註冊開通會員集點功能，系統方能以會員註冊的手機號碼，進行門市會員以及官網會員的串聯，合併門市及官網消費所累積的點數。</div>
          <div className="title">保持與鄧師傅的連結，點數永不過期！</div>
          <div>
            <ul className="sub">
              <li>1. 點數領取後365天內須使用完畢，未使用的點數，系統將自動收回。</li>
              <li>2. 以上期間內再次消費集點或兌換，則可延長點數使用期限365天。</li>
              <li>3. 系統無法溯及既往，請恕此服務限定2022.01.28改制後有消費的會員。</li>
            </ul>
          </div>
        </div>

        <footer.container />
      </div>
    )
  }
}

export default MemberNoticePageComponent;
