import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Modal } from 'antd';
// images
import toShoppingCartIcon from '../../images/toShoppingCart.svg'


class RedeemsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryId: -1
    };
  }
  componentDidMount() {
    this.props.onLoadProductsPage();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.categoryId === -1) {
      if (this.props.categoryId) {
        this.setState({ categoryId: this.props.categoryId }, () => this.props.onClickProductCategory(this.props.categoryId));
      } else {
        this.setState({ categoryId: '0' }, () => this.props.onClickProductCategory('0'));
      }
    }
  }
  onClickAuthMember(history, productId) {
    if (this.props.memberId > 0) {
      this.props.onClickProductShoppingCart(productId)
    }else {
      Modal.info({
        content: (
          <div>請登入後，繼續兌換紅利點數</div>
        ),
        onOk() { history.push(`/login`); },
      });
    }
  }

  render() {
    const { 
      productCategoryIdList, productCategories, productIdList, products, selectedProductCategoryId, purchaserEmail, orderId, onClickProductCategory
    } = this.props;
    // 不知為何線上付款導回來後，無法抓到 localstorage 裡的值，但導到其它頁(比如首頁)後就抓得到，所以在這判斷若有 orderId 與 purchaserEmail 就是從線上付款導回來的，就導到訂單完成頁
    if (purchaserEmail !== '' && orderId !== '') {
      this.props.history.push(`/shopping/completed`);
    }
  	return (
	    <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 SideBox d-none d-lg-block">
            {
              // (productCategoryIdList && productCategories)
              // &&
              // productCategoryIdList.map(productCategoryId => (
              //   <p key={productCategoryId} className={`SideBoxList ${selectedProductCategoryId === productCategoryId?"active":""}`} onClick={(event) => {productCategoryId !== '0' ? this.props.history.push(`/redeems/${productCategoryId}`) :  this.props.history.push(`/redeems`);onClickProductCategory(productCategoryId)}}>
              //     ▶  {productCategories.getIn([productCategoryId, "categoryName"])}
              //   </p>                
              // ))
            }
          </div>
          {/* <div className="row d-lg-none categorySelect">
            <div className="col-sm-12">
              <select id="categorySelect" style={{width:'100%'}} onChange={() => {onClickProductCategory(document.getElementById("categorySelect").value)}}>
              {
                (productCategoryIdList && productCategories)
                &&
                productCategoryIdList.map(productCategoryId => (
                  <option className="SideBoxListRWD" id={productCategoryId} key={productCategoryId} value ={productCategoryId}>{productCategories.getIn([productCategoryId, "categoryName"])}</option>
                ))
              }
            </select>
            </div>
          </div> */}

          <div className="col-lg-8 col-sm-12 ListContent">
            <div className="row justify-content-start">
              {
                (productIdList && products && productCategories)
                &&
                productIdList.map(productId => (
                  <div className="col-lg-4 col-md-6 List ProductList" key={productId}>
                    <div className={products.getIn([productId, "quantity"]) <= 0? "ImgGridBox  numEmpty": "ImgGridBox"}>
                      <Link to={`/redeem/${productId}`}>
                        { 
                          products.getIn([productId, "isNew"])
                          &&
                          <div className="Triangle">
                            <div className="Label">NEW</div>
                          </div>
                        }
                        <div className="ImgBox center-block">
                          <img src={`/packages/m/${products.getIn([productId, "image"])}`} className="img-fluid" alt=""/>
                        </div>
                        {/*
                          products.getIn([productId, "shippingDate"])
                          &&
                          <div className="ShippingDate">到貨日：{products.getIn([productId, "shippingDate"])}</div>
                        */}
                        <div className="Quantity">倒數：{products.getIn([productId, "quantity"])<0 ? 0 : products.getIn([productId, "quantity"])}</div>
                      </Link>
                    </div>
                    <div className="row">
                      <div className="col-8">
                        <div className="ProductsName">{products.getIn([productId, "name"])}</div>
                        <div className="ProductsPrice">換購點數：{products.getIn([productId, "redeem"])}</div>
                      </div>
                      <div className="col-4">
                        {
                          productId !== "103"
                          ?
                          <div className="ShoppingCart" onClick={(event) => this.onClickAuthMember(this.props.history, productId) }>
                            {
                              products.getIn([productId, "quantity"]) > 0
                              &&
                              <img className="img-fluid" src={toShoppingCartIcon} alt=""/>
                            }
                          </div>
                          :
                          <div className="ShoppingCart">
                            <Link to={`/specials`}>
                              <img className="img-fluid" src={toShoppingCartIcon} alt=""/>
                            </Link>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                ))
              } 
            </div>                 
          </div>
        </div>
	    </div>
	  )
  }
}

export default withRouter(RedeemsComponent);