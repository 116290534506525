import Immutable from 'immutable';

export const State = Immutable.fromJS({
  account: '',
  password: '',
  errMessage: '',
  memberId: null,
  showLoading: false,
  memberRedeem: null
});
