import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
import order from '../modules/order';
// images
import myAccountBanner from '../images/myAccountBanner.png'


class OrderPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
  	return (
	    <div id="page-order">
      <Helmet>
        <title>鄧師傅功夫菜</title>
        <meta name="description" content="飄香高雄35載，滷豬腳年銷售超越50座臺北101大樓高度! 馳名海內外，獲得媒體一致推薦。Chef Teng founded in 1984, Taiwan, is famous for signature stewed pork knuckles and has won awards and recognition from news media around the world." />
      </Helmet>
        <headerBar.container/>
        
        {/* <div className="MyAccountBannerTitle">
          <p className="center">我的帳戶</p>
        </div>         */}
        <div className="row TitleBox">
          <p className="col-auto mr-auto Title">訂單明細</p>
          <div className="col-auto DirectionTitle">首頁 / 我的帳戶 / 訂單明細</div>
        </div>
        <order.container
          orderId={this.props.match.params.orderId}
        />

        <footer.container/>
	    </div>
	  )
  }
}

export default OrderPageComponent;
