import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import ForgotPasswordComponent from './component';


const mapStateToProps = (state) => ({
  email: selectors.getEmail(state)
});

const mapDispatchToProps = dispatch => ({
  onSubmitForgotPassword: (history) => {
    dispatch(actions.doSubmitForgotPassword(history));
  },
  onEditForgotPassword: (value) => {
    dispatch(actions.doEditForgotPassword({ value }));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordComponent);