import React, { Component } from 'react';
import ecpay from '../modules/ecpay';


class ShoppingEcpayPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
  	return (
	    <div>
        <ecpay.container/>
	    </div>
	  )
  }
}

export default ShoppingEcpayPageComponent;
