import { createAction } from 'redux-actions';
import { HISTORY_CATEGORY_LIST } from './constants';
import * as types from './actionTypes';
import * as api from '../../utils/api';


export const doClickProductHistory = createAction(types.UPDATE_SELECTED_PRODUCT_HISTORY_ID)
export const doClickProductHistoryCategory = createAction(types.UPDATE_SELECTED_PRODUCT_HISTORY_CATEGORY_ID)

export const doUpdateProductHistory = createAction(types.UPDATE_PRODUCT_HISTORY)
export const doUpdateProductHistoryIdList = createAction(types.UPDATE_PRODUCT_HISTORY_ID_LIST)
export const doUpdateProductHistoryCategories = createAction(types.UPDATE_PRODUCT_HISTORY_CATEGORIES)
export const doUpdateProductHistoryCategoryIdList = createAction(types.UPDATE_PRODUCT_HISTORY_CATEGORY_ID_LIST)
export function doLoadProductHistoryPage() {  
  return dispatch => {
    const productHistoryCategoryIdList = [];
    const productHistoryCategories = {};
    HISTORY_CATEGORY_LIST.forEach(category => {
      productHistoryCategoryIdList.push(`${category.id}`);
      productHistoryCategories[category.id] = category;
    })
    dispatch(doUpdateProductHistoryCategoryIdList({ productHistoryCategoryIdList }));
    dispatch(doUpdateProductHistoryCategories({ productHistoryCategories }));

    api.rProductHistory().then(result => {   
      const productHistoryIdList = [];
      const productHistory = {};
      result.forEach(n => {
        productHistoryIdList.push(`${n.inspectionId}`);
        productHistory[n.inspectionId] = n;
      })
      dispatch(doUpdateProductHistoryIdList({ productHistoryIdList }));
      dispatch(doUpdateProductHistory({ productHistory }));
    }).catch((errMsg) => {
      console.log(errMsg)
    })
  }
}


