import { createAction } from 'redux-actions';
import { message } from 'antd';
import * as types from './actionTypes';
import * as api from '../../utils/api';
import * as config from '../../config'

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 3,
});


export function doLoadEcpay(id) {  
  return dispatch => {
    api.rEcpayParams(id).then(result => {  
      /*
      var form = document.createElement("form");
      form.method = "POST";
      form.action = config.ECPAY_URL;
      form.style.display = 'none';
      Object.keys(result).forEach(key => {
        let input = document.createElement("input"); 
        input.name = key;
        input.value = result[key];
        form.appendChild(input);
      });
      document.body.appendChild(form);
      form.submit();
      */
      
      var form = window.document.createElement('form');
      form.setAttribute('method', 'POST');
      form.setAttribute('action', config.ECPAY_URL);
      form.style.display = 'none';
      form.style.visibility = 'hidden';
      Object.keys(result).forEach(key => {
        let input = window.document.createElement("input"); 
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', key);
        input.setAttribute('value', result[key]);
        form.appendChild(input);
      });      
      window.document.body.appendChild(form);
      form.submit();         
    }).catch((errMsg) => {
      if (errMsg === 'E010') {
        message.warning('訂單已取消');
      }
    })
  }
}