import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
import banner from '../images/page-memberQA.jpg';

class MemberQAPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div id="page-memberQA">
        <Helmet>
          <title>鄧師傅功夫菜-會員 Q&A</title>
          <meta name="description" content="飄香高雄35載，滷豬腳年銷售超越50座臺北101大樓高度! 馳名海內外，獲得媒體一致推薦。Chef Teng founded in 1984, Taiwan, is famous for signature stewed pork knuckles and has won awards and recognition from news media around the world." />
        </Helmet>
        <headerBar.container />
        <div className="row TitleBox">
          <p className="col-auto m-auto Title Tab">
            <span>
              <Link className="title" to={`/redeems`}>
                兌換品項
              </Link>
            </span>
            <span>
              <Link className="title" to={`/redeemInfo`}>
                集點須知
              </Link>
            </span>
            <span>
              <Link className="title" to={`/memberNotice`}>
                會員新制公告
              </Link>
            </span>
            <span className="titleActive">
              會員 Q&A
            </span>
            <span>
              <Link className="title" to={`/loveLighting`}>
                用愛點亮餐桌
              </Link>
            </span>
          </p>
        </div>

        <div className="content">
          <img src={banner} alt="鄧師傅會員須知" />
          <ul>
            <li>
              <div>Q1. 請問怎麼開通門市會員？</div>
              <div>A1. 門市會員，須於鄧師傅功夫菜官方 LINE@ 中的會員專區，註冊以利開通門市集點功能。<a href="https://line.me/R/ti/p/%40wtz8539j">LINE@ 官方帳號（@CHEFTENG）</a></div>
            </li>
            <li>
              <div>Q2. 成為鄧師傅功夫菜門市會員，有甚麼好處？</div>
              <div>A2. 首次加入【鄧師傅功夫菜門市會員】者，即可獲得 30 點會員點數。每次消費單筆滿 30 元，即可獲得 1 點，累積點數兌換各式商品。除此之外，會員還享有會員專屬的生日禮，讓鄧師傅為您慶生！當然，最新消息以及不定期的優惠，一定搶先通知會員。成為鄧師傅功夫菜會員的好處多多，就等著你來享受。</div>
            </li>
            <li>
              <div>Q3. 請問開通門市會員後，也等於我是鄧師傅功夫菜官方網站的會員了嗎？</div>
              <div>A3. 門市與官網的會員系統不同。成為官網會員，須於鄧師傅功夫菜官方網站註冊以利開通官網購物集點功能。建議同時開通門市會員以及官網會員，不僅可以合併門市及官網消費所累積的點數，且兌換的商品也有更多選擇。</div>
            </li>
            <li>
              <div>Q4. 請問我在門市累積的點數，要怎麼跟官網累積的點數合併呢？</div>
              <div>A4. 門市會員以及官網會員皆須用【同一支手機號碼】註冊開通會員集點功能，系統方能以會員註冊的手機號碼，進行門市會員以及官網會員的串聯，合併門市及官網消費所累積的點數。</div>
            </li>
            <li>
              <div>Q5. 請問我 2022.01.28 後就沒有再消費了，我於 2021.06.15 消費累積的點數，什麼時候會過期呢？</div>
              <div>A5. 若是您 2022.01.28 後，皆無至門市或官網消費，您 2021.06.15 累積的點數，將於 2022.06.14 失效。建議您，可在 2022.06.14 前至門市或官網消費，舊點數即可延長效期，持續累積點數！</div>
            </li>
            <li>
              <div>Q6. 請問兌換商品中，沒有我想要的商品，我的點數又即將到期，請問我可以換現金嗎？或是指定其他商品嗎？</div>
              <div>A6. 點數可兌換之贈品內容將不定期更新，會員不得要求兌換現金，或兌換非贈品的品項。</div>
            </li>
            <li>
              <div>Q7. 請問我註冊門市會員，以及官網會員時，用不同的手機號碼註冊，可以合併點數嗎？</div>
              <div>A7. 系統可依照【同一支手機號碼】來合併會員資料，若是須將註冊不同手機號碼的會員資料合併，要麻煩會員提供不同手機號碼為同一會員所有的相關證明文件給工程師另行處理。</div>
            </li>
            <li>
              <div>Q8. 請問我新辦了門號，我原本門號的點數會失效嗎？</div>
              <div>A8. 會員註冊之手機掛失或新辦門號等，可重新註冊後，提出相關證明申請會員點數移轉。</div>
            </li>
            <li>
              <div>Q9. 請問當日消費未集點，能夠補登點數嗎？</div>
              <div>A9. 公司為維護所有會員權益，避免顧客點數被冒名補登，若遇顧客消費未補登點數，請於消費3日內提供收據供客服查核辦理，逾期不予補登。</div>
            </li>
          </ul>
        </div>

        <footer.container />
      </div>
    )
  }
}

export default MemberQAPageComponent;
