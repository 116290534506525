import { createSelector } from 'reselect';

export const getAllCouponIdList = state => state.getIn(['coupons', 'couponIdList']);
export const getCoupons = state => state.getIn(['coupons', 'coupons']);
export const getSearchText = state => state.getIn(['coupons', 'searchText']);
export const getStatusFilter = state => state.getIn(['coupons', 'statusFilter']);

export const getCouponIdList = createSelector(
  [getAllCouponIdList, getCoupons, getSearchText, getStatusFilter],
  (couponIdList, coupons, searchText, statusFilter) => {
		return couponIdList.filter(couponId => {
			if (statusFilter !== '' && coupons.getIn([couponId, 'couponStatus']) != statusFilter) {
			  return false;
			} 
			if (searchText === '') {
				return true;
			}
			if (coupons.getIn([couponId, 'couponCode']).toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
				return true
			}			
			if (coupons.getIn([couponId, 'couponDescription']).toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
				return true
			}
			if (coupons.getIn([couponId, 'startDay']).toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
				return true
			}			
			if (coupons.getIn([couponId, 'endDay']).toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
				return true
			}			
			return false;
		})
	}
)

export const getAvailableCouponCount = createSelector(
  [getAllCouponIdList, getCoupons],
  (couponIdList, coupons) => couponIdList.filter(couponId => coupons.getIn([couponId, 'couponStatus']) === 1).size
)