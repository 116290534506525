import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
import media from '../modules/media';
// images
// import newsBanner from '../images/newsBanner.png'
import newsPoint01 from '../images/media_newsPoint/newsPoint01.jpeg';
import newsPoint02 from '../images/media_newsPoint/newsPoint02.jpeg';
import newsPoint03 from '../images/media_newsPoint/newsPoint03.jpeg';
import newsPoint04 from '../images/media_newsPoint/newsPoint04.jpeg';
import newsPoint05 from '../images/media_newsPoint/newsPoint05.jpeg';

class MediaPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="container-fluid padding0" id="page-news">
        <Helmet>
          <title>鄧師傅功夫菜-媒體報導</title>
          <meta name="description" content="飄香高雄35載，滷豬腳年銷售超越50座臺北101大樓高度! 馳名海內外，獲得媒體一致推薦。Chef Teng founded in 1984, Taiwan, is famous for signature stewed pork knuckles and has won awards and recognition from news media around the world." />
        </Helmet>
        <headerBar.container />

        {/* <div className="NewsBannerTitle">
          <p className="center">媒體報導</p>
        </div>         */}
        <div className="row TitleBox">
          <p className="col-auto m-auto Title Tab">
            <span>
              <Link className="title" to={`/story`}>
                品牌故事
              </Link>
            </span>
            <span className="titleActive">
              媒體報導
            </span>
            <span>
              <Link className="title" to={`/shoppingNotes`}>
                購物須知
              </Link>
            </span>
          </p>
        </div>
        <div className="rowBox row">
          <div className="highlights">
            <a href="https://www.cw.com.tw/article/5101292?template=transformers">引用：天下雜誌，〈家傳菜變冷凍明星商品！鄧師傅兄弟檔接班，老字號台菜進化了〉</a>
            <h2>家傳菜變冷凍明星商品！鄧師傅兄弟檔接班，老字號台菜進化了</h2>
            <p>想把台菜口味做到標準化不容易。鄧師傅功夫菜接班人、2位留美歸國兄弟檔，如何擴大經營，做到標準化，還搶攻冷凍食品市場？</p>
            <div className="img first">
              <img src={newsPoint01} alt="鄧師傅媒體報導" />
              <span className="imgTxt">左起為鄧師傅廚藝總監鄧至廷、創辦人鄧文裕，以及鄧師傅執行長鄧至佑。兄弟接班家業，要以標準化和冷凍食品商機開拓新的道路。圖片來源：黃明堂攝</span>
            </div>
            <p>
              10多年前，鄧師傅功夫菜執行長鄧至佑曾在美國著名的連鎖中式速食「熊貓快餐」擔任地區主管，受到震撼教育：一個與中國菜毫無淵源的墨西哥裔洗車工人，晚上兼差到「熊貓快餐」當「廚師」，竟能照著標準步驟（SOP），炒出風味絲毫不差的宮保雞丁。<br />
              <br />
              幾年後他回台灣接掌家族企業，就打算以滷豬腳等招牌菜，在高雄飄香36年的鄧師傅功夫菜，導入美國連鎖餐飲的標準化管理。<br />
              <br />
              「熊貓」能讓生手當大廚的祕訣之一，是把宮保雞丁的鹽、油、糖等複雜調味濃縮做成一種神祕醬汁，廚師只要倒固定分量下去炒就可以。<br />
              <br />
              「這是騙外國人的，」鄧至佑的哥哥、鄧師傅功夫菜廚藝總監鄧至廷，一開始很難接受。然而仔細檢視後，發現某個程度的標準化還是有其必要。<br />
            </p>
            <h3 className="subtitle">傳承老味道，要有標準做法</h3>
            <p>兄弟接班之後，常聽到客人抱怨，鄧師傅的招牌菜滷豬腳，不同分店味道竟然有差別。</p>
            <blockquote>
              <p>滷豬腳的師傅，都是師承兄弟倆父親、鄧師傅創辦人鄧文裕，最後卻因做法沒有統一，味道逐漸偏差。「要訂下標準值，才能把味道傳承下去，」鄧至佑說。</p>
            </blockquote>
            <div className="img">
              <img src={newsPoint02} alt="鄧師傅媒體報導" />
              <span className="imgTxt">鄧師傅招牌菜滷豬腳。（黃明堂攝）</span>
            </div>
            <h3 className="subtitle">熊貓的概念，是讓門市員工的料理程序愈簡易愈好，而真正影響風味的關鍵步驟則全部事先在食品工廠解決，因此鄧師傅也決定朝這方向進行。</h3>
            <p>
              2008年，他們建置一座小型中央廚房，集中生產滷豬腳、滷蹄膀等核心產品，並處理前置作業複雜的料理。<br />
              <br />
              例如：宮保雞丁的雞肉，就在中央餐廚用機器按摩、醃製入味。而廚師在門市，只要專心把料理該有的香氣炒出來、顏色配好即可。<br />
            </p>
            <div className="img">
              <img src={newsPoint03} alt="鄧師傅媒體報導" />
              <span className="imgTxt">鄧師傅店內用餐環境。（黃明堂攝）</span>
            </div>
            <blockquote>
              <p>因為這座中央餐廚，鄧師傅走了不同的路。相較其他餐飲二代競相在門市做創新，他們反而轉向上游整合，將家傳料理做成冷凍食品。</p>
            </blockquote>
            <p>
              最初發想，來自冷凍年菜。鄧至廷2000年讀完書回台，在高雄福華飯店吃年夜飯時，觀察到生意很好，「不是每戶人家都能自己煮一桌年菜，這有市場。」<br />
              <br />
              隔年他先做豬腳外賣。後來更在南部極受歡迎的汪笨湖電視節目上銷售，母親節檔期大賣1500份豬腳，遠遠超過預期。<br />
              <br />
              兄弟倆更加確定，可以在餐廳外拓展新生意。這一步更讓鄧師傅在多年後的疫情中，逆勢成長。<br />
              <br />
              鄧至佑說，今年3、4、5月，鄧師傅官網冷凍食品銷售比去年同期多10倍，「我們把冷凍食品放進冰箱，擺到3間街邊店門口賣，竟帶動營收成長兩成。」<br />
              <br />
            </p>
            <h3 className="subtitle">目前食品銷售已經佔鄧師傅年營業額10%。包含豬腳、元寶花生、冰糖雪梨等30樣冷凍食品，在統一超、家樂福等通路銷售，產量供不應求。</h3>
            <p>
              因此兄弟倆決定在大社蓋一座食品廠，預計總投資約4億台幣，明年開始生產，希望能提升食品營收佔比到30%。冷凍食品做出成績，上一輩也安心放手。<br />
              <br />
              鄧至廷笑說，當初買一台40萬真空包裝機，就被爸媽念，現在買一台600多萬，連懷疑都沒有。<br />
              <br />
              留著八字鬍的創辦人鄧文裕（見下圖中），採訪當天也來了，直誇兒子們，「做餐飲很辛苦，當初送他們到國外念書，希望以後回台灣教書，沒想過他們會接班。」（責任編輯：陳郁雁）
            </p>
            <div className="img">
              <img src={newsPoint04} alt="鄧師傅媒體報導" />
              <span className="imgTxt">左為鄧師傅廚藝總監鄧至廷，右為鄧師傅執行長鄧至佑。（黃明堂攝）</span>
            </div>
            <div className="img">
              <img src={newsPoint05} alt="鄧師傅媒體報導" />
            </div>

          </div>
        </div>

        <media.container />

        <footer.container />
      </div>
    )
  }
}

export default MediaPageComponent;
