import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
// import bootstrap from 'bootstrap';
import { DatePicker, Button } from 'antd';
import shoppingCart from '../shoppingCart'
// import images
import logo_headerbar from '../../images/logo_headerbar.png';
import icon_order from '../../images/icon_order.svg';
import icon_user from '../../images/icon_user.svg';
import icon_shoppingcart from '../../images/icon_shopping-cart.svg';
import icon_menu from '../../images/icon_menu.svg';


const selectedItemClass = {
  '/stores': 'selectStores',
  '/media': 'selectMedia',
  '/products': 'selectProducts',
  '/specials': 'selectSpecials',
  '/story': 'selectStory',
  '/heating-instructions': 'selectHeating-instructions',
  '/kungfu-noodle': 'selectKungfu-noodle',
  '/product-history': 'selectProduct-history',
  '/redeems': 'selectRedeems',
  '/delivery': 'selectDelivery',
}


class HeaderBarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerItems: [
        {
          'name': '門市資訊',
          'url': '/stores'
        },
        {
          'name': '外送菜單',
          'url': '/delivery'
        },
        {
          'name': '線上購物',
          'url': '/products'
        },
        // {
        //   'name': '自由配',
        //   'url': '/specials'
        // },
        {
          'name': '集點換購',
          'url': '/redeems'
        },
        // {
        //   'name': '品牌故事',
        //   'url': '/story'
        // },
        // {
        //   'name': '煨功夫麵',
        //   'url': '/kungfu-noodle'
        // },
        {
          'name': '加熱說明',
          'url': '/heating-instructions'
        },
        {
          'name': '食材履歷',
          'url': '/product-history'
        },
        {
          'name': '關於我們',
          'url': '/aboutUs'
        },
        {
          'name': 'ENGLISH',
          'url': '/en'
        },
      ],
      currentItemUrl: null,
      showShoppingCart: false,
      isInShoppingCart: true,
      isInUserDropdown: true,
      shoppingCartTimeoutInterval: null
    };
    this.onMouseLeaveShoppingCart = this.onMouseLeaveShoppingCart.bind(this);
    // this.onMouseEnterShoppingCart = this.onMouseEnterShoppingCart.bind(this);
    this.onMouseLeaveUser = this.onMouseLeaveUser.bind(this);
    this.onMouseEnterUser = this.onMouseEnterUser.bind(this);
    this.onHideAllDropdown = this.onHideAllDropdown.bind(this);
    this.handleShoppingCart = this.handleShoppingCart.bind(this);
  }
  onMouseLeaveShoppingCart() {
    this.setState({ showShoppingCart: false });
    this.setState({ isInShoppingCart: false });
  }
  // onMouseEnterShoppingCart() {
  //   if (this.props.location.pathname !== '/shopping/progress') {
  //     this.setState({ showShoppingCart: true })
  //   } 
  //   this.setState({ isInShoppingCart: true })
  // }
  onMouseLeaveUser() {
    this.setState({ isInUserDropdown: false });
  }
  onMouseEnterUser() {
    this.setState({ showShoppingCart: false });
    this.setState({ isInShoppingCart: false });
    this.setState({ isInUserDropdown: true });
  }
  onHideAllDropdown() {
    if (!this.state.isInShoppingCart && !this.state.isInUserDropdown) {
      this.props.onLeaveUser();
      this.setState({ isInShoppingCart: false });
      this.setState({ showShoppingCart: false });
    }
  }
  componentDidMount() {
    document.addEventListener('click', this.onHideAllDropdown, true);
    var shoppingCartTimeoutInterval = setInterval(this.props.onShoppingCarTimeout, 10000);
    this.setState({ shoppingCartTimeoutInterval: shoppingCartTimeoutInterval });
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.onHideAllDropdown, true);
    clearInterval(this.state.shoppingCartTimeoutInterval);
  }
  handleShoppingCart() {
    // this.props.getLogginState();
    this.props.onLeaveUser();
    if (this.props.location.pathname != "/shopping/progress") {
      if (!this.state.showShoppingCart) {
        this.setState({ isInShoppingCart: true });
        this.setState({ showShoppingCart: true });
        document.addEventListener('click', this.onHideAllDropdown, true);
      } else {
        this.setState({ isInShoppingCart: false });
        document.removeEventListener('click', this.onHideAllDropdown, true);
      }

      if (this.state.showShoppingCart) {
        this.setState({ isInShoppingCart: false });
        this.setState({ showShoppingCart: false });
      }
    }
  }
  render() {
    const {
      name, showUserDropDown, onLeaveUser, shoppingCount,
      onClickUser, onClickLogout
    } = this.props;
    return (
      <div>

        <nav className="MenuBarNav navbar navbar-light navbar-expand-lg bg-light justify-content-end fixed-top">
          <Link className="navbar-brand mr-auto" to={`/`}>
            <img src={logo_headerbar} alt="" />
          </Link>
          <button className="navbar-toggler MenuBtn" type="button" data-toggle="collapse" data-target="#collapsingNavbar2">
            <img src={icon_menu} alt="" />
          </button>
          <div className="nav-item-group d-flex justify-content-end order-lg-4">
            <ul>
              <li className="nav-item">
                <Link to={`/orders/search`}>
                  <i>
                    <img className="nav-link NavIcon" src={icon_order} alt="" />
                  </i>
                </Link>
              </li>

              <li className="nav-item"
                onMouseEnter={this.handleShoppingCart}
                onClick={e => this.props.onClickShoppingCart()}
              >
                <Link className="nav-link NavIcon" to={`/shopping/progress`}>
                  <i className="ShoppingCartIcon"><img src={icon_shoppingcart} alt="" />
                    {
                      (shoppingCount !== 0)
                      &&
                      <span className="RedBadge">{shoppingCount}</span>
                    }
                  </i>
                </Link>
              </li>

              <li className="nav-item account-user">
                <i onClick={(event) => onClickUser(this.props.history)}>
                  <img className="nav-link NavIcon" src={icon_user} alt=""
                    onMouseEnter={() => this.onMouseEnterUser()}
                  />
                  {
                    (this.props.isLoggedIn && name !== '')
                    &&
                    <span className="loginPoint">
                      歡迎回來，{name}
                    </span>
                  }
                  {
                    showUserDropDown
                    &&
                    <div className="AccountBox"
                      onMouseEnter={() => this.onMouseEnterUser()}
                      onMouseLeave={() => { onLeaveUser(); this.onMouseLeaveUser() }}
                    >
                      <div className="arrowuser arrowuser-border"></div>
                      <div className="arrowuser arrowuser-bg"></div>
                      <p className="AccountText">
                        <Link to={`/orders`}>我的帳戶</Link>
                      </p>
                      <p className="AccountText tip">
                        我的點數：{this.props.memberRedeem}
                      </p>
                      <p className="AccountText" onClick={(event) => onClickLogout()}>
                        登出
                      </p>
                    </div>
                  }
                </i>
              </li>
            </ul>
          </div>
          <div className="navbar-collapse collapse order-lg-2" id="collapsingNavbar2">
            <ul className="NavBarText navbar-nav">
              {
                this.state.headerItems.map(headerItem => (
                  <li className="nav-item" key={headerItem.url}>
                    <Link className="nav-link" to={headerItem.url} onClick={(event) => this.onClickTab()}>
                      <p className="NavText">{headerItem.name}</p>
                      {
                        this.props.location.pathname === headerItem.url
                        &&
                        <div className={`${selectedItemClass[headerItem.url]}`}></div>
                      }
                    </Link>
                  </li>
                ))
              }
            </ul>
          </div>

          {
            this.state.showShoppingCart
            &&
            <div onMouseLeave={this.onMouseLeaveShoppingCart}>
              <shoppingCart.container />
            </div>
          }
        </nav>

      </div>
    )
  }
}

export default withRouter(HeaderBarComponent);
