import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { URL } from 'url';
import { withRouter } from 'react-router-dom';
import btn_prev from '../../images/btn_prev.svg'
import btn_close from '../../images/slider-close-icon.svg'

class Ecpay2Component extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
    const queryParams = new URLSearchParams(this.props.location.search);
    // this.props.onLoadEcpay(queryParams.get('id'), queryParams.get('email'), queryParams.get('pay'));
    this.props.onLoadEcpay(queryParams.get('id'));
  }   
  render() {
    const { 
    } = this.props;
    
    return (
      <div>
      </div>
    )
  }
}

export default withRouter(Ecpay2Component);
