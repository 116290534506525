import { createAction } from 'redux-actions';
import { message, Modal } from 'antd';
import moment from 'moment';
import * as types from './actionTypes';
import * as api from '../../utils/api';
import * as cookie from "../../utils/cookiesTool";
import * as validation from '../../utils/validation';

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});


export const doUpdateIsLoggedIn = createAction(types.UPDATE_IS_LOGGED_IN);
export function doIsLoggedIn(history) {
    return dispatch => {
        if (cookie.getCookie('stormedToken') === '') {
            dispatch(doUpdateIsLoggedIn({ isLoggedIn: false }));
            Modal.confirm({
                title: '您尚未登入會員，是否要先登入？',
                content: '(成為會員即可享有購物優惠等福利)',
                okText: '登入',
                cancelText: '繼續',
                onOk() {
                    history.push(`/login`);
                },
            });
        }
    }
}

export const doUpdateProducts = createAction(types.UPDATE_PRODUCTS);
export function doLoadProducts() {  
  return (dispatch, getState) => {
    const state = getState();
    const buyProducts = state.getIn(['shoppingProgress', 'buyProducts']);
    const buyRedeems = state.getIn(['shoppingProgress', 'buyRedeems']);
    const buyProductIdList = [];
    buyProducts.keySeq().forEach(productId => buyProductIdList.push(productId));
    buyRedeems.keySeq().forEach(productId => buyProductIdList.push(productId));
    api.rPartialPackages(buyProductIdList).then(result => {
      const products = {};
      result.forEach(product => {
        products[product.id] = product;
      })
      dispatch(doUpdateProducts({ products }));
    }).catch((errMsg) => {
      console.log(errMsg);
    })    
  }
}

export const doUpdateShoppingData = createAction(types.UPDATE_SHOPPING_DATA);
export function doClickProgress(payload) {
  return (dispatch, getState) => {
    dispatch(doUpdateShoppingData({value: payload.value, type:"currentStep"}));
    payload.history.push(`/shopping/progress`);
  }
}

export const doUpdateBuyProducts = createAction(types.UPDATE_BUY_PRODUCTS);
export const doUpdateShoppingCount = createAction(types.UPDATE_SHOPPING_COUNT);
export function doUpdateBuyProductQuantity(productId, buyQuantity) {
  return dispatch => {
    dispatch(doUpdateBuyProducts({ productId, buyQuantity }));
    dispatch(doUpdateShoppingCount({ count: buyQuantity }));
  }
}

export function doClickRemoveBuyProduct(productId, buyQuantity) {
  return (dispatch, getState) => {
    const state = getState();
    const buyProducts = state.getIn(['shoppingProgress', 'buyProducts']);

    dispatch(doUpdateBuyProducts({ productId, buyQuantity }));
    dispatch(doUpdateShoppingCount({ count: -buyProducts.getIn([productId]) }));
  }
}

export const doUpdateBuyRedeems = createAction(types.UPDATE_BUY_REDEEMS);
export function doUpdateBuyRedeemQuantity(productId, buyQuantity) {
  return (dispatch, getState) => {
    if (buyQuantity >= 0) {
      const state = getState();
      const redeemProducts = state.getIn(['redeems', 'products']);
      const buyRedeems = state.getIn(['shoppingProgress', 'buyRedeems']);
      const memberRedeem = state.getIn(['login', 'memberRedeem']);
      let productRedeem = 0;
      buyRedeems.keySeq().forEach(packageId => {
        productRedeem += buyRedeems.getIn([packageId]) * redeemProducts.getIn([packageId, 'redeem'])
      })
      console.log(memberRedeem, productRedeem, (buyQuantity * redeemProducts.getIn([productId, 'redeem'])))
      if (memberRedeem <= 0 || memberRedeem < productRedeem + (buyQuantity * redeemProducts.getIn([productId, 'redeem']))) {
        message.info('點數不足');
        return
      }
    }

    dispatch(doUpdateBuyRedeems({ productId, buyQuantity }));
    dispatch(doUpdateShoppingCount({ count: buyQuantity }));
  }
}

export function doClickRemoveBuyRedeem(productId, buyQuantity) {
  return (dispatch, getState) => {
    const state = getState();
    const buyRedeems = state.getIn(['shoppingProgress', 'buyRedeems']);

    dispatch(doUpdateBuyRedeems({ productId, buyQuantity }));
    dispatch(doUpdateShoppingCount({ count: -buyRedeems.getIn([productId]) }));
  }
}
