import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
import englishImage1 from '../images/englishPage1.jpg'
import englishImage2 from '../images/englishPage2.jpg'
import englishImage3 from '../images/englishPage3.jpg'
import englishImage4 from '../images/englishPage4.jpg'
import englishImage5 from '../images/englishPage5.jpg'
import englishImage6 from '../images/englishPage6.jpg'

class EnglishPageComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
    onClickScrollToBottom() {
      const node = ReactDOM.findDOMNode(this.bottom);
      node.scrollIntoView({ behavior: "smooth" });
    }
    render() {
      return (
        <div id="page-main">
          <Helmet>
            <title>鄧師傅功夫菜</title>
            <meta name="description" content="飄香高雄35載，滷豬腳年銷售超越50座臺北101大樓高度! 馳名海內外，獲得媒體一致推薦。Chef Teng founded in 1984, Taiwan, is famous for signature stewed pork knuckles and has won awards and recognition from news media around the world." />
          </Helmet>
          <headerBar.container />
          
          
          <div className="english">
            <div className="row englishItem">
                <div className="col-md-12 col-12">
                  <img src={englishImage1} className="img-fluid englishImage" alt="" />
                  <img src={englishImage2} className="img-fluid englishImage" alt="" />
                  <img src={englishImage3} className="img-fluid englishImage" alt="" />
                  <img src={englishImage4} className="img-fluid englishImage" alt="" />
                  <img src={englishImage5} className="img-fluid englishImage" alt="" />
                  <img src={englishImage6} className="img-fluid englishImage" alt="" />
                </div>
            </div>
          </div>




          <footer.container ref={(el) => { this.bottom = el }} />
        </div>
      )
    }
  }
  
  export default EnglishPageComponent;
