import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';


class ProfileComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
    this.props.onIsLoggedIn(this.props.history);    
    this.props.onLoadProfile();
    this.props.onLoadTaiwanCity();
  }
  render() {
    const { 
      name, gender, email, phone, city, address, TaiwanCity,
      onChangeName, onChangeGender, onChangeEmail, onChangeCity, onChangeAddress, onSubmitProfile
    } = this.props;
  	return (
	    <div>

        <form onSubmit={(event) => {event.preventDefault();onSubmitProfile()}}>

          <table className="table">
            <tbody>
              <tr>
                <td className="TD1"><p className="RegistrationText">姓名/稱謂 (Name/Title)</p></td>
                <td className="TD2"><div className="TableGoldLine"></div></td>
                <td className="TD3">
                  <input className="RegistrationInput" type="text"
                    value={name}
                    onChange={(event) => onChangeName(event.target.value)}
                    required
                  />

                  <div className="RadioText">
                  <label className="normalFont"><input className="RegistrationInput"  type="radio" name="gender" value="male"
                      checked={gender === "male"}
                      onChange={(event) => onChangeGender(event.target.value)}
                    />&nbsp;&nbsp;先生 &nbsp;&nbsp;&nbsp;&nbsp;</label>

                    <label className="normalFont"><input className="RegistrationInput" type="radio" name="gender" value="female"
                      checked={gender === "female"}
                      onChange={(event) => onChangeGender(event.target.value)}
                    />&nbsp;小姐</label>
                  </div>
                </td>
              </tr>

              <tr>
                <td><p className="RegistrationText">電子郵件 (E-mail)</p></td>
                {/* <td><div className="TableGoldLine"></div></td>
                <td><p className="RegistrationText">{email}</p></td> */}
                <td><div className="TableGoldLine"></div></td>
                <td>
                  <input className="RegistrationInput"  type="email"
                    value={email}
                    onChange={(event) => onChangeEmail(event.target.value)}
                    required
                  />  
                </td>
              </tr>

              <tr>
                <td><p className="RegistrationText">行動電話 (Mobile)</p></td>
                {/* <td><div className="TableGoldLine"></div></td>
                <td>
                  <input className="RegistrationInput"  type="Tel" pattern="[0-9]{10}"
                    value={phone}
                    onChange={(event) => onChangePhone(event.target.value)}
                    required
                  />  
                </td> */}
                <td><div className="TableGoldLine"></div></td>
                <td><p className="RegistrationText">{phone}</p></td>
              </tr>

              <tr>
                <td><p className="RegistrationText">收貨地址 (Address)</p></td>
                <td><div className="TableGoldLine"></div></td>
                <td>
                  <select id="categorySelectShopping" value={city} onChange={(event) => onChangeCity(event.target.value)}>
                    {
                      TaiwanCity.map(t  => (
                        <option key={t.getIn(["id"])} value={t.getIn(["city"])}>{t.getIn(["city"])}</option>
                      ))
                    }
                  </select>
                  <input className="RegistrationInput" type="text"
                    value={address}
                    onChange={(event) => onChangeAddress(event.target.value)}
                    required
                  /> 
                </td>
              </tr>

            </tbody>
          </table>

          <div className="container">
            <div className="row">
              <div className="RegistrationBtnBox col-md-3"></div>
              <div className="RegistrationBtnBox col-md-3">
                <Link to={`/orders`}>
                  <button className="ReturnBtn_small" type="button">返回</button>
                </Link>
              </div>
              <div className="RegistrationBtnBox col-md-3">
                <button className="SendBtn_small" type="submit">儲存</button>
              </div>
              <div className="RegistrationBtnBox col-md-3"></div>
            </div>
          </div>
        </form>
	    </div>
	  )
  }
}

export default withRouter(ProfileComponent);