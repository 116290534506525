import React, { Component } from 'react';


class ForgotPasswordComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
    this.props.onEditForgotPassword('');
  }  
  render() {
    const { 
      email,
      onEditForgotPassword, onSubmitForgotPassword
    } = this.props;

  	return (
	    <div>
        <form onSubmit={(event) => {event.preventDefault();onSubmitForgotPassword(this.props.history)}}>
          <p className="LoginTitle">忘記密碼</p>
          <p className="PasswordText">請輸入您的E-mail，系統將會寄送確認信給您</p>

          <input type="email" placeholder="user@domain.com" 
            value={email} 
            onChange={(event) => onEditForgotPassword(event.target.value)}
            required 
          />

          <p className="PasswordText">
            註1：請填寫正確電子郵件信箱，以利收到訂單確認信函 <br/>
            註2：因免費信箱及yahoo郵件容易將信件歸類為垃圾郵件，為取得即時訂單訊息請避免使用，謝謝 
          </p>

          <button className="LoginBtn" type="submit">繼續</button>
        </form>
	    </div>
	  )
  }
}

export default ForgotPasswordComponent;
