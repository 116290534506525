import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';
import * as cookie from "../../utils/cookiesTool";


const Reducer = handleActions({
  'shoppingProgress/UPDATE_IS_LOGGED_IN': (state, { payload }) => {
    return state.setIn(['isLoggedIn'], payload.isLoggedIn);
  },
  'shoppingProgress/UPDATE_IS_COMPLETED': (state, { payload }) => {
    return state.setIn(['isCompleted'], payload.isCompleted);      
  },      
  'shoppingProgress/UPDATE_BUY_PRODUCTS': (state, { payload }) => {
    const now = new Date();
    cookie.setCookie('shoppingTime', now.toString());
    const buyProducts = state.getIn(['buyProducts']);
    // console.log(buyProducts.toJS())
    if (payload) {
      if (payload.buyQuantity === 0) {
        if (buyProducts.has(payload.productId)) {
          const newBuyProducts = buyProducts.delete(payload.productId);
          return state.setIn(['buyProducts'], newBuyProducts);
        } else {
          return state;
        }
      } else {
        let qty = payload.buyQuantity;
        if (buyProducts.has(payload.productId)) {
          qty += state.getIn(['buyProducts',payload.productId]);
        }
        if (qty > 0) {
          return state.setIn(['buyProducts', payload.productId], qty); 
        }
      }
    } else {
      return state.setIn(['buyProducts'], fromJS({}));
    }
  },
  'shoppingProgress/UPDATE_BUY_REDEEMS': (state, { payload }) => {
    const now = new Date();
    cookie.setCookie('shoppingTime', now.toString());
    const buyRedeems = state.getIn(['buyRedeems']);
    // console.log(buyProducts.toJS())
    if (payload) {
      if (payload.buyQuantity === 0) {
        if (buyRedeems.has(payload.productId)) {
          const newBuyRedeems = buyRedeems.delete(payload.productId);
          return state.setIn(['buyRedeems'], newBuyRedeems);
        } else {
          return state;
        }
      } else {
        let qty = payload.buyQuantity;
        if (buyRedeems.has(payload.productId)) {
          qty += state.getIn(['buyRedeems',payload.productId]);
        }
        if (qty > 0) {
          return state.setIn(['buyRedeems', payload.productId], qty); 
        }
      }
    } else {
      return state.setIn(['buyRedeems'], fromJS({}));
    }
  },
  'shoppingProgress/UPDATE_PRODUCTS': (state, { payload }) => {
    return state.setIn(['products'], fromJS(payload.products));
  }, 
  'shoppingProgress/UDPATE_SHIPPING_LIMIT': (state, { payload }) => {
    return state.setIn(['canShippingSelf'], payload.canShippingSelf)
                .setIn(['canShippingDelivery'], payload.canShippingDelivery)
                .setIn(['canShippingTC'], payload.canShippingTC)
                .setIn(['shippingSelfDateList'], payload.shippingSelfDateList)
                .setIn(['shippingDeliveryDateList'], payload.shippingDeliveryDateList)
                .setIn(['shippingTCDateList'], payload.shippingTCDateList);
  },
  'shoppingProgress/UPDATE_STORE_ID_NAMES': (state, { payload }) => {
    return state.setIn(['storeIdNames'], fromJS(payload.storeIdNames));
  },
  'shoppingProgress/UPDATE_SHOPPING_DATA': (state, { payload }) => {
    return state.setIn([payload.type], fromJS(payload.value));
  },
  'shoppingProgress/UPDATE_ORDER_ID': (state, { payload }) => {
    return state.setIn(['orderId'], payload.orderId);
  },
}, State);

export default Reducer;