// import { createSelector } from 'reselect';

export const getIsPosting = state => state.getIn(['registration', 'isPosting']);
export const getErrMsg = state => state.getIn(['registration', 'errMsg']);

export const getEmail = state => state.getIn(['registration', 'email']);
export const getGender = state => state.getIn(['registration', 'gender']);
export const getName = state => state.getIn(['registration', 'name']);
export const getCity = state => state.getIn(['registration', 'city']);
export const getAddress = state => state.getIn(['registration', 'address']);
export const getPhone = state => state.getIn(['registration', 'phone']);
export const getPassword = state => state.getIn(['registration', 'password']);
export const getConfirmPassword = state => state.getIn(['registration', 'confirmPassword']);
export const getErrMessage = state => state.getIn(['registration', 'errMessage']);
export const getTaiwanCity = state => state.getIn(['registration', 'TaiwanCity']);
