import Immutable from 'immutable';

export const State = Immutable.fromJS({
  isPosting: false,
  errMsg: '',
  name: '',
  email: '',
  gender: 'male',
  city: '台北市',
  address: '',
  phone: '',
  password: '',
  confirmPassword: '',
  TaiwanCity: {},
});
