import { handleActions } from 'redux-actions';
// import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  'forgotPassword/EDIT_FORGOT_PASSWORD': (state, { payload }) => {
    return state.setIn(['email'], payload.value);
  },
  'forgotPassword/UPDATE_MESSAGE': (state, { payload }) => {
    return state.setIn(['message'], payload.message);
  }  
}, State);

export default Reducer;