import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Popover, Modal } from 'antd';
// images
import minusIcon from '../../images/minus.svg'; // -
import plusIcon from '../../images/plus.svg'; // +


class ShoppingCartComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
    this.props.onLoadProducts();
  };
  onCheckRedeemAndNext(history) {
    if(this.props.totalRedeem > this.props.memberRedeem) {
      if(this.props.memberId > 0 ){
        Modal.info({
          content: (<div>點數不足，請重新確認商品</div>),
          onOk() { history.push(`/shopping/progress`); },
        });
      } else {
        Modal.info({
          content: (<div>請重新登入</div>),
          onOk() { history.push(`/login`); },
        });
      }
    } else {
      this.props.onIsLoggedIn(this.props.history);
      this.props.onClickProgress(this.props.history, 1)
    }
  }

  render() {
    const {
      buySpecials, buyProducts, buyRedeems, products, totalAmount,
      onUpdateBuyProductsQty, onDeleteBuyProducts, onClickRemoveBuyProduct, onUpdateBuyProductQuantity, onUpdateBuyRedeemQuantity, onClickRemoveBuyRedeem, onClickProgress, onUpdateShoppingData
    } = this.props;
    return (
      <div className="ShoppingCartBox" key={`shoppingCart`}>

        <div className="arrow arrow-border"></div>
        <div className="arrow arrow-bg"></div>

        <p className="ShoppingCartText">最近加入的商品</p>
        <div className="ShoppingCartOverflow">
        {
          buySpecials
          &&
          buySpecials.valueSeq().map(specials => specials.getIn(['buyProductsList']).map((buyProducts, index) => 
            <div className="ShoppingCartContent_headerbar" key={`shopping_cart_specials_${index}`}>
              <div className="row ShoppingCartCommodityBlock">
                <div className="col ShoppingCartImgBox">
                  <img className="ShoppingCartImg" src={specials.getIn(['image'])} alt={specials.getIn(['name'])} />
                </div>
                <div className="col">
                  <Popover content={specials.getIn(['name'])} trigger="hover" placement="left">
                    <p className="ShoppingCartTitle">{specials.getIn(['name'])}</p>
                  </Popover>
                  <div className="d-flex align-items-center QuantityBar">
                    <button className="Minus" type="button" onClick={e => onUpdateBuyProductsQty(specials.getIn(['id']), index, buyProducts.getIn(['qty'])-1)}><img src={minusIcon} alt="" /></button>
                    <input className="QuantityNumber" type="number" name="quantity" value={buyProducts.getIn(['qty'])} disabled />
                    <button className="Plus" type="button" onClick={e => onUpdateBuyProductsQty(specials.getIn(['id']), index, buyProducts.getIn(['qty'])+1)}><img src={plusIcon} alt="" /></button>                   
                  </div>
                </div>
                <div className="PriceBox">
                  <p className="ShoppingCartPrice">${specials.getIn(['price']) * buyProducts.getIn(['qty'])}</p>
                  <button className="CheckCartBtnDelete" type="button" onClick={e => onDeleteBuyProducts(specials.getIn(['id']), index)}>刪除</button>
                </div>
              </div>
              { /* 最後一個不顯示分隔線 */
                (buyProducts.size > 0 || index !== specials.getIn(['buyProductsList']).size - 1)
                &&
                <div className="ShoppingCartDividers"></div>
              }
            </div>
          ))
        }
        {
          (buyProducts, products)
          &&
          buyProducts.keySeq().map((productId, index) =>
            <div className="ShoppingCartContent_headerbar" key={`shopping_cart_${productId}`}>

              <div className="row ShoppingCartCommodityBlock">
                <div className="col ShoppingCartImgBox">
                  <img className="ShoppingCartImg" src={`/packages/sxs/${products.getIn([productId, "image"])}`} alt={products.getIn([productId, "name"])} />
                </div>
                <div className="col">
                  <Popover content={products.getIn([productId, "name"])} trigger="hover" placement="left">
                    <p className="ShoppingCartTitle">{products.getIn([productId, "name"])}</p>
                  </Popover>
                  <div className="d-flex align-items-center QuantityBar">
                    <button className="Minus" type="button" onClick={(event) => onUpdateBuyProductQuantity(productId, -1)} disabled={buyProducts.getIn([productId]) == 1}><img src={minusIcon} alt="" /></button>
                    <input className="QuantityNumber" type="number" name="quantity" value={buyProducts.getIn([productId])} onChange={(event) => onUpdateBuyProductQuantity(productId, event.target.value)} disabled />
                    <button className="Plus" type="button" onClick={(event) => onUpdateBuyProductQuantity(productId, +1)} disabled={products.getIn([productId, "quantity"]) <= buyProducts.getIn([productId])}><img src={plusIcon} alt="" /></button>                   
                  </div>
                </div>
                <div className="PriceBox">
                  <p className="ShoppingCartPrice">${+products.getIn([productId, "price"]) * +buyProducts.getIn([productId])}</p>
                  <button className="CheckCartBtnDelete" type="button" onClick={() => onClickRemoveBuyProduct(productId)}>刪除</button>
                </div>
              </div>
              { /* 最後一個不顯示分隔線 */
                index !== buyProducts.keySeq().size - 1
                &&
                <div className="ShoppingCartDividers"></div>
              }
            </div>
          )
        }
        {
          (buyRedeems, products)
          &&
          buyRedeems.keySeq().map((productId, index) =>
            <div className="ShoppingCartContent_headerbar" key={`shopping_cart_${productId}`}>
              <div className="row ShoppingCartCommodityBlock">
                <div className="col ShoppingCartImgBox">
                  <img className="ShoppingCartImg" src={`/packages/sxs/${products.getIn([productId, "image"])}`} alt={products.getIn([productId, "name"])} />
                </div>
                <div className="col">
                  <p className="ShoppingCartTitle">{products.getIn([productId, "name"])}</p>
                  <div className="d-flex align-items-center QuantityBar">
                    <button className="Minus" type="button" onClick={(event) => onUpdateBuyRedeemQuantity(productId, -1)} disabled={buyRedeems.getIn([productId]) == 1}><img src={minusIcon} alt="" /></button>
                    <input className="QuantityNumber" type="number" name="quantity" value={buyRedeems.getIn([productId])} onChange={(event) => onUpdateBuyRedeemQuantity(productId, event.target.value)} disabled />
                    <button className="Plus" type="button" onClick={(event) => onUpdateBuyRedeemQuantity(productId, +1)} disabled={products.getIn([productId, "quantity"]) <= buyRedeems.getIn([productId])}><img src={plusIcon} alt="" /></button>                   
                  </div>
                </div>
                <div className="PriceBox">
                  <p className="ShoppingCartPrice">{+products.getIn([productId, "redeem"]) * +buyRedeems.getIn([productId])} 點</p>
                  <button className="CheckCartBtnDelete" type="button" onClick={() => onClickRemoveBuyRedeem(productId)}>刪除</button>
                </div>
              </div>
              { /* 最後一個不顯示分隔線 */
                index !== buyRedeems.keySeq().size - 1
                &&
                <div className="ShoppingCartDividers"></div>
              }
            </div>
          )
        }
        </div>
        {
          (buySpecials.size > 0 || buyProducts.keySeq().size > 0 || buyRedeems.keySeq().size > 0)
          &&
          <div className="CheckCartBtnBox">
            <button className="CheckCartBtn" type="button" onClick={() => onClickProgress(this.props.history,0)}>查看購物車</button>
            &nbsp;&nbsp;&nbsp;
            <button className="CheckCartBtn" type="button" onClick={() => { onUpdateShoppingData(`${totalAmount}`, "totalAmount"); this.onCheckRedeemAndNext(this.props.history); }}>立即結帳</button>
          </div>
        }
      </div>
    )
  }
}

export default withRouter(ShoppingCartComponent);