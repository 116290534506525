import { connect } from 'react-redux';
// import * as actions from './actions';
// import * as selectors from './selectors';
import FooterComponent from './component';


const mapStateToProps = (state) => ({
  // account: selectors.getAccount(state),
  // password: selectors.getPassword(state),
  // errMessage: selectors.getErrMessage(state)
});

const mapDispatchToProps = dispatch => ({
  // onSubmitLogin: () => {
  //   dispatch(actions.doSubmitLogin());
  // },
  // onEditLogin: (value, type) => {
  //   dispatch(actions.doEditLogin({ value, type }));
  // }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FooterComponent);