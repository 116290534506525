import { createAction } from 'redux-actions';
import { message } from 'antd';
import * as types from './actionTypes';
import * as api from '../../utils/api';
import * as cookie from '../../utils/cookiesTool';


const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});

export function doIsLoggedIn(history) {
  return dispatch => {
    dispatch(doUpdateOldPassword({ oldPassword: '' }));
    dispatch(doUpdateNewPassword({ newPassword: '' }));
    dispatch(doUpdateConfirmPassword({ confirmPassword: '' }));
    dispatch(doUpdateIsReset({ isReset: false }));    
    const stormedToken = cookie.getCookie('stormedToken');
    if (stormedToken === '') {
      history.push(`/login`);
    } else {
      api.cCheckToekn().then(result => {
      }).catch((errMsg) => {
        if (errMsg === 'E401') {
          message.info('Invalid Token');
          cookie.setCookie('stormedToken', '');
          history.push('/');
        }
      })
    }
  }
}

export const doUpdateOldPassword = createAction(types.UPDATE_OLD_PASSWORD)
export const doUpdateNewPassword = createAction(types.UPDATE_NEW_PASSWORD)
export const doUpdateConfirmPassword = createAction(types.UPDATE_CONFIRM_PASSWORD)
export const doUpdateIsReset = createAction(types.UPDATE_IS_RESET)
export const doUpdateErrorMessage = createAction(types.UPDATE_ERROR_MESSAGE)

export function doSubmitResetPassword(history) {
  return (dispatch, getState) => {
  	const state = getState();
    const oldPassword = state.getIn(['resetPasswordWithOldPassword', 'oldPassword']);
  	const newPassword = state.getIn(['resetPasswordWithOldPassword', 'newPassword']);
  	const confirmPassword = state.getIn(['resetPasswordWithOldPassword', 'confirmPassword']);
    // console.log(state.getIn(['resetPasswordWithOldPassword', 'errMessage']));

    dispatch(doUpdateErrorMessage({ message: ''}));

    if (oldPassword === newPassword) {
      dispatch(doUpdateOldPassword({ oldPassword: '' }));
      dispatch(doUpdateNewPassword({ newPassword: '' }));
      dispatch(doUpdateConfirmPassword({ confirmPassword: '' }));
      dispatch(doUpdateErrorMessage({ message: '密碼相同，請重新輸入'}));
      return;
    }

    if (newPassword !== confirmPassword) {
      dispatch(doUpdateOldPassword({ oldPassword: '' }));
      dispatch(doUpdateNewPassword({ newPassword: '' }));
      dispatch(doUpdateConfirmPassword({ confirmPassword: '' }));
      dispatch(doUpdateErrorMessage({ message: '密碼不一致，請重新輸入'}));
      return;
    }

    api.uPasswordReset(oldPassword, newPassword).then(result => {
      cookie.setCookie('stormedToken', '');
      dispatch(doUpdateIsReset({ isReset: true }));      
    }).catch((errMsg) => {
      if (errMsg === 'E401') {
        history.push(`/login`);
      } else if (errMsg === 'E400') {
        dispatch(doUpdateErrorMessage({ message: 'Bad Request'}));
      } else if  (errMsg === 'E002') {
        // dispatch(doUpdateErrorMessage({ message: '密碼需8位數以上且英數字大小寫混和'}));
        dispatch(doUpdateErrorMessage({ message: '密碼需4位數以上'}));
      }
    })    
  }
}
