import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  'ordersSearch/UPDATE_ORDER_PHONE': (state, { payload }) => {
    return state.setIn(['orderPhone'], payload.orderPhone);
  },	
  'ordersSearch/UPDATE_ORDER_ID': (state, { payload }) => {
    return state.setIn(['orderId'], payload.orderId);
  },
  'ordersSearch/UPDATE_ORDER': (state, { payload }) => {
    return state.setIn(['order'], fromJS(payload.order));
  },
}, State);

export default Reducer;